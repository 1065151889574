















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import store from '@/services/store';
import { formLst } from '@/modules/budget-request/components/js/budgetFormsLst';
import { variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';

@Component({
    name: 'c-budget-req-head',
    components: {
        'multiselect': Multiselect
    }
})
export default class CLimitFilter extends Vue {
    @Prop({
        required: false,
        default: 'Год'
    })
    private nameYear!: string;

    @Prop({
        required: false,
        default: null
    })
    private filter!: any | null;

    @Prop({
        required: false,
        default: false
    })
    private openDisabled!: boolean; // disabled кнопка открыть
    private budgetLevel: number[] = [];
    // -------------------------------
    private obl: string | null = null;

    private regionBase: any[] = [];
    private curRegion: any | null = null;
    private variantDate: any | null = null;

    private curYear = null;
    private year: number | null = null;
    private maxYear = 3000;
    private minYear = 2000;

    private periodLst: any[] = [];
    private curPeriod: any | null = null;
    private abpBase: any[] = [];
    private curAbp: any | null = null;
    // -------------------------------
    private get regions(): any[] {
        const res = [];
        for (const el of this.regionBase) {
            res.push(this.setNameLang(el, 'code'));
        }
        if (this.curRegion !== null) { this.curRegion = this.setNameLang(this.curRegion, 'code'); }
        res.sort((a, b) => (a.code - b.code > 0) ? 1 : -1);
        return res;
    }

    public openFilterByRef(refName: any) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    private getPeriodLst() {
        const year = (new Date()).getFullYear();
        this.periodLst = [];
        for (let i = 0; i < 3; i++) {
            this.periodLst.push({ name: `${year - 1 + i} - ${year - 1 + i + 2}`, year: year - 1 + i });
            if (year === (year - 1 + i + 1)) {
                this.curPeriod = { name: `${year + 1} - ${year + 3}`, year: year + 1 };
                this.year = year + 1;
                this.maxYear = year + 3;
                this.minYear = year + 1;
            }
        }
        this.periodLst.reverse();
    }

    // ------------------ администратор программ ------------------
    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }

        if (this.curAbp !== null) { this.curAbp = this.setNameLang(this.curAbp, 'abp'); }
        return res;
    }

    private async chgAbp() {
        await this.loadProg();
    }

    private async chgPlanPeriod() {
        await this.getBudgetVariantsData();
        await this.getObl();
    }

    // ------------------------ программа --------------------------
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        if (this.curProg !== null) { this.curProg = this.setNameLang(this.curProg, 'prg'); }
        return res;
    }

    private async chgProg() {
        await this.loadSubProg();
    }
    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        if (this.curSubProg !== null) {
            this.curSubProg = this.setNameLang(this.curSubProg, 'ppr');
        }
        return res;
    }
    // ----------------------------- load -----------------------------------

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({ idArr: [el.id] }, el));
            }
        }
        return result;
    }

    private async loadAbp() {
        let response: any = [];
        this.abpBase = [];
        if (this.usrId === null) { return; }
        if (response.length === 0) {
            try {
                if (this.variantDate!==null) {
                    response = await fetch(`/api-py/get-abp-by-msu-region/${this.curRegion.code}/${this.variantDate.beg_date}/${this.variantDate.end_date}`);
                    response = await response.json();
                }
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
            }
        }
        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.abpBase = this.setIdArr(response, 'abp');

        if (this.abpBase.length > 0) {
            this.curAbp = this.abpBase[0];
        } else {
            this.curAbp = null;
        }
        await this.chgAbp();
    }

    private async loadProg() {
        let response: any = [];
        this.progBase = [];
        if (response.length === 0 && this.curAbp !== null) {
            try {
                if (this.variantDate!==null) {
                    response = await fetch('/api-py/get-dict-func-childs-by-parent-type/prg/' + this.curAbp.abp + `/4/${this.variantDate.beg_date}/${this.variantDate.end_date}`);
                    response = await response.json();
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки программ', (error as Error).toString());
            }
        }

        response.sort((a: any, b: any) => (a.prg - b.prg > 0) ? 1 : -1);
        this.progBase = this.setIdArr(response, 'prg');

        if (this.progBase.length > 0) {
            this.curProg = this.progBase[0];
        } else {
            this.curProg = null;
        }
        await this.chgProg();
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];
        if (this.curProg !== null) {
            try {
                if (this.variantDate!==null) {
                    response = await fetch('/api-py/get-dict-func-childs-by-parent-type/ppr/' + this.curAbp.abp + '/' + this.curProg.prg + `/5/${this.variantDate.beg_date}/${this.variantDate.end_date}`);
                    response = await response.json();
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки подпрограмм', (error as Error).toString());
                response = [];
            }
        }

        response.sort((a: any, b: any) => (a.ppr - b.ppr > 0) ? 1 : -1);
        this.subProgBase = this.setIdArr(response, 'ppr');

        if (this.subProgBase.length > 0) {
            this.curSubProg = this.subProgBase[0];
        } else {
            this.curSubProg = null;
        }
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }
    // ----------------------------------

    private async created() {
        this.getPeriodLst();
        await this.getObl();

        this.$watch('usrId', async () => { if (this.usrId) {
            await this.getObl();
        } });
        this.$watch('curPeriod', this.chgCurPeriod);
        await this.chgData();
    }

    private async chgCurPeriod() {
        if (this.curPeriod) {
            this.curYear = this.curPeriod.year;
            this.year = this.curYear;
            this.maxYear = this.curPeriod.year + 2;
            this.minYear = this.curPeriod.year;
        }
    }

    private async getObl() {
        this.curRegion = [];
        this.regionBase = [];
        if (!this.usrId) { return; }
        try {
            const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);

            if (result.status === 200) {
                const json = await result.json();
                this.obl = json.obl;
            } else {
                this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
        }
        try {
            const regUrl = '/api-py/get-list-regions-with-variants-date/' + this.obl + '/' + this.usrId + '/' + this.curPeriod.year;
            await fetch(regUrl)
                .then(response => response.json())
                .then(json => {
                    if (json.length && json[0].code) { this.regionBase = json; }
                });
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-user-budget-region', (error as Error).toString());
        }
        if (this.regionBase.length > 0) {
            this.curRegion = this.regionBase[0];
        } else {
            this.curRegion = null;
        }
        await this.getBudgetVariantsData();
      if (this.variantDate===null) {
          this.makeToast('warning', 'Предупреждение', 'Не найдена версия бюджета для выбранных параметров');
      }
    }

    private async getBudgetVariantsData(){
        let response: any = [];
        if (this.year!=null && this.curPeriod.year && this.curRegion!=null){
            try {
                response = await fetch('/api-py/get-budget-variants-by-params/' + this.curPeriod.year + '/' + this.curRegion.code);
                response = await response.json();
                if (Object.keys(response).length !== 0) {
                    this.variantDate = response[0];
                    await this.loadAbp();
                } else {
                    this.variantDate = null;
                    await this.loadAbp();
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки варианта', (error as Error).toString());
            }
        }
    }

    private addNullsToDigits(num: any, size: any){
        let val = num.toString();
        while (val.length<size){
            val = '0' + val;
        }
        return val;
    }

    private async chgRegion() {
        await this.getBudgetVariantsData();
        await this.loadAbp();
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        if (obj === null) { return null; }
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private async chgData() {
        this.curYear = this.curPeriod.year;
        if (this.year !== null) {
            this.year = parseInt(this.year.toString());
        }
        const data = {
            year: this.curYear,
            yearProp: this.year,
            // gr: this.curGr,
            abp: this.curAbp,
            prg: this.curProg,
            pgr: this.curSubProg,
            region: this.curRegion.code,
            regionDetails: this.curRegion
        };
        // console.log('data: ' + JSON.stringify(data));
        this.$emit('chgData', data);

        const drop: any = this.$refs.drop;
        drop.hide(true);
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
