<template>
    <div>
        <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
        />
        <b-alert
            v-model="showInTop"
            class="position-fixed fixed-top m-0 rounded-0"
            style="z-index: 3000 !important;"
            variant="danger"
            dismissible
        >
            Внимание: обнаружены изменения, не соответствующие установленным лимитам. Пожалуйста, обновите информацию из штатного расписания.
        </b-alert>
        <div class="filter-container">
            <div class="left-content">
                <c-limit-filter ref="budgetHeader" @chgData="chgData" nameYear="Прогнозный год"></c-limit-filter>
            </div>
            <div class="right-content">
                <div class="filter-actions">
                    <b-button variant="danger" @click="deleteDataToDB()" :disabled="!attributeStatus">Удалить</b-button>
                    <b-button variant="warning" @click="autoCount()" :disabled="!attributeStatus">Авторасчёт</b-button>
                    <b-button variant="primary" @click="showModalSpfForm(true)"><i class="icon icon-plus-circle"></i>Добавить специфику</b-button>
                    <b-button variant="secondary" @click="updateDataFromShR()" :disabled="!attributeStatus"><i class="icon icon-refresh"></i> Обновить данные из ШР</b-button>
                    <b-button variant="success" @click="saveDataToDB('all')" :disabled="!attributeStatus">Сохранить</b-button>
                </div>
            </div>
        </div>
        <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="curYear" @click="openFilterByRef('curYearRef')">
                    {{ curYear }}-{{ curYear + 2 }}
                </span>
            <span class="item-breadcrumb" v-if="currentRegion" @click="openFilterByRef('curRegionRef')">
                    {{ currentRegion }}-{{ regionName }}
                </span>
            <span class="item-breadcrumb" v-if="curAbp" @click="openFilterByRef('abpRef')">
                    {{curAbp}}-АБП
            </span>
            <span class="item-breadcrumb" v-if="curProg" @click="openFilterByRef('prgRef')">
                    {{curProg}}-БП
            </span>
            <span class="item-breadcrumb" v-if="curSubProg" @click="openFilterByRef('pprRef')">
                    {{curSubProg}}-БПП
            </span>
        </div>
        <div class="table-container">
            <div class="b-table-sticky-header table-responsive-true">
                <table class="table b-table table-bordered">
                    <thead>
                    <tr>
                        <th>КОД
                            <b-form-checkbox
                                v-model="selectAllRows"
                                @change="e => checkboxSelectAllRow(e)"
                                :value="true"
                                :unchecked-value="false" />
                        </th>
                        <th>ИСПОЛНЕНИЕ ЗА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-2 }} ГОД</th>
                        <th>УТОЧНЕННЫЙ ПЛАН НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-1 }}Г. (БЕЗ РБ)</th>
                        <th>В Т.Ч. РАЗОВЫЕ РАСХОДЫ</th>
                        <th>ТРАНСФЕРТЫ ИЗ РБ</th>
                        <th>СКОРРЕКТИРОВАННЫЙ ПЛАН НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-1 }}</th>
                        <th>УТОЧНЕННЫЙ ПЛАН НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-1 }} ГОД (БЕЗ УЧЕТА РАЗОВЫХ РАСХОДОВ)</th>
                        <th>ПРОГНОЗ НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear) }}</th>
                        <th>В Т.Ч. РАСХОДЫ, УЧТЕННЫЕ СВЕРХ ПАРАМЕТРОВ</th>
                        <th>% РОСТА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear) }}</th>
                        <th>ПРОГНОЗ НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+1 }} ГОД</th>
                        <th>% РОСТА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+1 }} ГОД</th>
                        <th>ПРОГНОЗ НА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+2 }} ГОД</th>
                        <th>% РОСТА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+2 }} ГОД</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><i class="icon icon-summ"></i></td>
                        <td class="text-right"><b>{{ $n(factSum, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(planSum, 'decimal') }}</b></td>
                        <td class="text-right"><b>{{ $n(oneTimeExpenses) }}</b></td>
                        <td class="text-right"><b>{{ $n(rbTransfert) }}</b></td>
                        <td class="text-right"><b>{{ $n(correctedPlan) }}</b></td>
                        <td class="text-right"><b>{{ $n(refinedPlan) }}</b></td>
                        <td class="text-right"><b>{{ $n(forecastYearPlusOne) }}</b></td>
                        <td class="text-right"><b>{{ $n(expensesInExcessOfParameters) }}</b></td>
                        <td class="text-right"><b>{{ isNaN(parseFloat(percentOfGrowth))===true ? '0.00' : isFinite(parseFloat(percentOfGrowth)) ? $n(percentOfGrowth, 'decimal') : '0.00' }}</b></td>
                        <td class="text-right"><b>{{ isNaN(parseFloat(twoYearPlusForecast))===true ? parseInt('0') : isFinite(parseInt(twoYearPlusForecast)) ? $n(twoYearPlusForecast) : parseInt('0') }}</b></td>
                        <td class="text-right"><b>{{ isNaN(parseFloat(twoYearPlusPercent))===true ? '0.00' : isFinite(parseFloat(twoYearPlusPercent)) ? $n(twoYearPlusPercent, 'decimal') : '0.00' }}</b></td>
                        <td class="text-right"><b>{{ isNaN(parseFloat(threeYearPlusForecast))===true ? parseInt('0') : isFinite(parseInt(threeYearPlusForecast)) ? $n(threeYearPlusForecast) : parseInt('0') }}</b></td>
                        <td class="text-right"><b>{{ isNaN(parseFloat(threeYearPlusPercent))===true ? '0.00' : isFinite(parseFloat(threeYearPlusPercent)) ? $n(threeYearPlusPercent, 'decimal') : '0.00' }}</b></td>
                        <td></td>
                    </tr>
                    <template v-for="(budgetItem, budgetIndex) of budgetFactPlanArr">
                        <tr :key="'budgetItemSpfIndx'+budgetIndex" class="bl-tr-sp1">
                            <td>
                                <span class="link-style" @click="getFuelTypesServices(true, budgetItem)" v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf">{{ budgetItem.spf }}</span>
                                <span class="link-style" @click="getUtilityServices(true, budgetItem)" v-else-if="budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf">{{ budgetItem.spf }}</span>
                                <span class="link-style" @click="getOtherServices(true, budgetItem)" v-else-if="budgetItem.spf === spfSpecificModal.modalSpecifics[2].spf">{{ budgetItem.spf }}</span>
                                <span v-else>{{ budgetItem.spf }}</span>
                            </td>
                            <td colspan="14">
                                <span class="link-style" @click="getFuelTypesServices(true, budgetItem)" v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf">{{ budgetItem.nameRu }}</span>
                                <span class="link-style" @click="getUtilityServices(true, budgetItem)" v-else-if="budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf">{{ budgetItem.nameRu }}</span>
                                <span class="link-style" @click="getOtherServices(true, budgetItem)" v-else-if="budgetItem.spf === spfSpecificModal.modalSpecifics[2].spf">{{ budgetItem.nameRu }}</span>
                                <span v-else>{{ budgetItem.nameRu }}</span>
                            </td>
                        </tr>
                        <tr :key="'budgetItemIndx'+budgetIndex">
                            <td>
                                <b-form-checkbox
                                    v-model="budgetItem.checkRow" @change="onRowChecked($event)" @input="e => { onInputRow(e) }">
                                </b-form-checkbox>
                            </td>
                            <td class="text-right td-bg-lightyellow">{{ $n(budgetItem.factSum) }}</td>
                            <td class="text-right">{{ $n(budgetItem.planSum) }}</td>
                            <td class="spf144And151">
<!--                              <template v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf">-->
<!--                                {{ isNaN(parseFloat(budgetItem.oneTimeExpenses))===true || isFinite(parseFloat(budgetItem.oneTimeExpenses)) || budgetItem.oneTimeExpenses==='' ? $n(parseInt(budgetItem.oneTimeExpenses)) : parseInt('0') }}-->
<!--                              </template>-->
<!--                              <template v-else>-->
                                <b-form-input
                                    class="text-right"
                                    :value="isNaN(parseFloat(budgetItem.oneTimeExpenses))!==true || isFinite(parseFloat(budgetItem.oneTimeExpenses)) || !isValueEmpty(budgetItem.oneTimeExpenses) ? $n(parseInt(budgetItem.oneTimeExpenses)) : parseInt('0')"
                                    @change="val => changeCellOneTimeExpenses(budgetItem, val)"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d{0,9}$')"
                                    @blur="inputFixed(budgetItem, 'oneTimeExpenses', budgetItem.oneTimeExpenses, 2)"
                                    :disabled="!attributeStatus"
                                >
                                </b-form-input>
<!--                              </template>-->
                            </td>
                            <td class="spf144And151">
<!--                              <template v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf">-->
<!--                                {{ isNaN(parseFloat(budgetItem.rbTransfert))===true || isFinite(parseFloat(budgetItem.rbTransfert)) || budgetItem.rbTransfert==='' ? $n(parseInt(budgetItem.rbTransfert)) : parseInt('0') }}-->
<!--                              </template>-->
<!--                              <template v-else>-->
                                <b-form-input
                                    class="text-right"
                                    :value="isNaN(parseFloat(budgetItem.rbTransfert))!==true || isFinite(parseFloat(budgetItem.rbTransfert)) || !isValueEmpty(budgetItem.rbTransfert) ? $n(parseInt(budgetItem.rbTransfert)) : parseInt('0')"
                                    @change="val => changeCellRbTransfert(budgetItem, val)"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d{0,9}$')"
                                    @blur="inputFixed(budgetItem, 'rbTransfert', budgetItem.rbTransfert, 2)"
                                    :disabled="!attributeStatus"
                                >
                                </b-form-input>
<!--                              </template>-->
                            </td>
                            <td class="spf144And151">
<!--                              <template v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf">-->
<!--                                {{ isNaN(parseFloat(budgetItem.correctedPlan))===true || isFinite(parseFloat(budgetItem.correctedPlan)) || budgetItem.correctedPlan==='' ? $n(parseInt(budgetItem.correctedPlan)) : parseInt('0') }}-->
<!--                              </template>-->
<!--                              <template v-else>-->
                                <b-form-input
                                    class="text-right"
                                    :value="isNaN(parseFloat(budgetItem.correctedPlan))!==true || isFinite(parseFloat(budgetItem.correctedPlan)) || !isValueEmpty(budgetItem.correctedPlan) ? $n(parseInt(budgetItem.correctedPlan)) : parseInt('0')"
                                    @change="val => changeCellCorrectedPlan(budgetItem, val)"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPressCorrectedPlan($event)"
                                    @blur="inputFixed(budgetItem, 'correctedPlan', budgetItem.correctedPlan, 2)"
                                    :disabled="!attributeStatus"
                                >
                                </b-form-input>
<!--                              </template>-->
                            </td>
                            <td class="text-right">
                                {{ isNaN(budgetItem.refinedPlan)!=true ? $n(parseInt(budgetItem.refinedPlan)) : parseInt('0') }}
                            </td>
                            <td class="td-bg-lightyellow">
                                <template v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[2].spf">
                                    {{ isNaN(parseFloat(budgetItem.forecastYearPlusOne))!==true || isFinite(parseFloat(budgetItem.forecastYearPlusOne)) || budgetItem.forecastYearPlusOne==='' ? $n(parseInt(budgetItem.forecastYearPlusOne)) : parseInt('0') }}
                                </template>
                                <template v-else>
                                    <div class="bl-td-xx">
                                        <div class="bl-td-xx-left">
                                            <template v-if="budgetItem.inflationRate">
                                                <span class="tempText">x{{ budgetItem.firstYearVal }}</span>
                                            </template>
                                            <template v-if="budgetItem.forecastYearPlusOneShr && parseInt(budgetItem.forecastYearPlusOneShr)!==parseInt(budgetItem.forecastYearPlusOneInit)">
                                                <div class="error">
                                                    <i class="icon icon-danger"></i>
                                                    <div class="pop-up">
                                                        <p class="red-text">Внимание! <br>Новые данные не соответствуют сохраненному лимиту: </p>
                                                        <p class="m-0">Сохраненные: {{ $n(parseInt(budgetItem.forecastYearPlusOneInit)) }}</p>
                                                        <p class="red-text m-0">Фактические: {{ $n(budgetItem.forecastYearPlusOneShr) }}</p>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="bl-td-xx-right">
                                        <b-form-input
                                            class="text-right"
                                            :value="isNaN(parseFloat(budgetItem.forecastYearPlusOne))!==true || isFinite(parseFloat(budgetItem.forecastYearPlusOne)) || budgetItem.forecastYearPlusOne==='' ? $n(parseInt(budgetItem.forecastYearPlusOne)): parseInt('0')"
                                            @change="val => changeCellForecast(budgetItem, val)"
                                            @keyup.enter.exact="keyup13"
                                            @keypress="keyPress($event, '^\\d{0,9}$')"
                                            @blur="inputFixed(budgetItem, 'forecastYearPlusOne', budgetItem.forecastYearPlusOne, 2)"
                                            :readonly="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[2].spf"
                                            :disabled="!attributeStatus"
                                        >
                                        </b-form-input>
                                        <template v-if="budgetItem.shrValue && parseInt(budgetItem.forecastYearPlusOne)!==parseInt(budgetItem.shrValue)">
                                            <span class="danger-text">{{ budgetItem.shrValue }}</span>
                                        </template>
                                    </div>
                                    </div>
                                </template>
                            </td>
                            <td>
                                <template>
                                <b-form-input
                                    class="text-right"
                                    :value="isNaN(parseFloat(budgetItem.expensesInExcessOfParameters))!==true || isFinite(parseFloat(budgetItem.expensesInExcessOfParameters)) || budgetItem.expensesInExcessOfParameters==='' ? $n(parseInt(budgetItem.expensesInExcessOfParameters)) : parseInt('0')"
                                    @change="val => changeCellExpenses(budgetItem, val)"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d{0,9}$')"
                                    @blur="inputFixed(budgetItem, 'expensesInExcessOfParameters', budgetItem.expensesInExcessOfParameters, 2)"
                                    :disabled="!attributeStatus"
                                >
                                </b-form-input>
                              </template>
                            </td>
                            <td class="text-right">
                                {{ budgetItem.percentOfGrowth }}
                            </td>
                            <td class="text-right td-bg-lightyellow">
                                <template v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[2].spf">
                                    {{ isNaN(parseFloat(budgetItem.twoYearPlusForecast))===true || isFinite(parseFloat(budgetItem.twoYearPlusForecast)) || budgetItem.twoYearPlusForecast==='' ? $n(parseInt(budgetItem.twoYearPlusForecast)) : parseInt('0') }}
                                </template>
                                <template v-else>
                                    <div class="bl-td-xx">
                                        <div class="bl-td-xx-left">
                                            <template v-if="budgetItem.inflationRate">
                                                <span class="tempText">x{{ budgetItem.secondYearVal }}</span>
                                            </template>
                                            <template v-if="budgetItem.twoYearPlusForecastShr && parseInt(budgetItem.twoYearPlusForecastShr)!==parseInt(budgetItem.twoYearPlusForecastInit)">
                                                <div class="error">
                                                    <i class="icon icon-danger"></i>
                                                    <div class="pop-up">
                                                        <p class="red-text">Внимание! <br>Новые данные не соответствуют сохраненному лимиту: </p>
                                                        <p class="m-0">Сохраненные: {{ $n(parseInt(budgetItem.twoYearPlusForecastInit)) }}</p>
                                                        <p class="red-text m-0">Фактические: {{ $n(budgetItem.twoYearPlusForecastShr) }}</p>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="bl-td-xx-with-input">
                                        <b-form-input
                                            class="text-right"
                                            :value="isNaN(parseFloat(budgetItem.twoYearPlusForecast))!==true || isFinite(parseFloat(budgetItem.twoYearPlusForecast)) || budgetItem.twoYearPlusForecast==='' ? $n(parseInt(budgetItem.twoYearPlusForecast)) : parseInt('0')"
                                            @keyup.enter.exact="keyup13"
                                            @change="val => changeCellForecastTwo(budgetItem, val)"
                                            @keypress="keyPress($event, '^\\d{0,9}$')"
                                            @blur="inputFixed(budgetItem, 'twoYearPlusForecast', budgetItem.twoYearPlusForecast, 2)"
                                            :readonly="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[2].spf"
                                            :disabled="!attributeStatus"
                                        >
                                        </b-form-input>
                                    </div>
                                    </div>
                                </template>
                            </td>
                            <td  class="text-right">
                                {{ budgetItem.twoYearPlusPercent }}
                            </td>
                            <td class="text-right td-bg-lightyellow">
                                <template v-if="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[2].spf">
                                    {{ isNaN(parseFloat(budgetItem.threeYearPlusForecast))===true || isFinite(parseFloat(budgetItem.threeYearPlusForecast)) || budgetItem.twoYearPlusForecast==='' ? $n(parseInt(budgetItem.threeYearPlusForecast)) : parseInt('0') }}
                                </template>
                                <template v-else>
                                    <div class="bl-td-xx">
                                        <div class="bl-td-xx-left">
                                            <template v-if="budgetItem.inflationRate">
                                                <span class="tempText">x{{ budgetItem.thirdYearVal }}</span>
                                            </template>
                                            <template v-if="budgetItem.threeYearPlusForecastShr && parseInt(budgetItem.threeYearPlusForecastShr)!==parseInt(budgetItem.threeYearPlusForecastInit)">
                                                <div class="error">
                                                    <i class="icon icon-danger"></i>
                                                    <div class="pop-up">
                                                        <p class="red-text">Внимание! <br>Новые данные не соответствуют сохраненному лимиту: </p>
                                                        <p class="m-0">Сохраненные: {{ $n(parseInt(budgetItem.threeYearPlusForecastInit)) }}</p>
                                                        <p class="red-text m-0">Фактические: {{ $n(budgetItem.threeYearPlusForecastShr) }}</p>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="bl-td-xx-right">
                                        <b-form-input
                                            class="text-right"
                                            :value="isNaN(parseFloat(budgetItem.threeYearPlusForecast))!==true || isFinite(parseFloat(budgetItem.threeYearPlusForecast)) || budgetItem.twoYearPlusForecast==='' ? $n(parseInt(budgetItem.threeYearPlusForecast)) : parseInt('0')"
                                            @change="val => changeCellForecastThree(budgetItem, val)"
                                            @keyup.enter.exact="keyup13"
                                            @keypress="keyPress($event, '^\\d{0,9}$')"
                                            @blur="inputFixed(budgetItem, 'threeYearPlusForecast', budgetItem.threeYearPlusForecast, 2)"
                                            :readonly="budgetItem.spf === spfSpecificModal.modalSpecifics[0].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[1].spf || budgetItem.spf === spfSpecificModal.modalSpecifics[2].spf"
                                            :disabled="!attributeStatus"
                                        >
                                        </b-form-input>
                                    </div>
                                  </div>
                                </template>
                            </td>
                            <td  class="text-right">
                                {{ budgetItem.threeYearPlusPercent }}
                            </td>
                            <td><i class="icon icon-trash delete-icon-td disabled" @click="removeSpfFromGrid(true, budgetItem)"></i></td>
                        </tr>
                    </template>
                    <tr>
                        <td>
                            <div class="text-center">
                                <i class="icon icon-plus-circle add-button" @click="showModalSpfForm(true)"></i>
                            </div>
                        </td>
                        <td colspan="13">
                            <b-link class="add-link" @click="showModalSpfForm(true)">
                                Добавить специфику
                            </b-link>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Модальное окном для 151 формы -->
        <b-modal
            v-model="modalUtilityServicesVisible"
            modal-class="modal-table add-abp"
            size="xl"
            title=""
            centered
        >
          <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <div class="title">Оплата коммунальных услуг:</div>
            <i class="icon icon-close" @click="close()"></i>
          </template>
          <div class="table-container">
            <div class="b-table-sticky-header table-responsive-true">
              <table class="table b-table table-bordered b-table-no-border-collapse">
                <thead>
                <tr>
                  <th class="utilityServices">№</th>
                  <th class="utilityServices">Наименование ком.услуг</th>
                  <th class="utilityServices">Расчетные данные из форм расчетов за {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-1 }} год</th>
                  <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear) }}</th>
                  <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+1 }}</th>
                  <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+2 }}</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(commItem, commIndex) of commServicesArr">
                  <tr :key="'commItemIndx'+commIndex" class="trTdCommService">
                    <td>{{ commItem.index }}</td>
                    <td>{{ commItem.nameRu }}</td>
                    <td>
                      <b-form-input
                          class="text-right inputCommService"
                          :value="$n(parseFloat(commItem.value).toFixed(2))"
                          readonly
                      >
                      </b-form-input>
                    </td>
                    <td>
                      <div class="bl-td-xx-with-input">
                        <span class="tempText">{{ commItem.valOne }}</span>
                        <b-form-input
                            class="text-right inputCommService"
                            :value="isNaN(parseFloat(commItem.countFirstVal))===true || isFinite(parseFloat(commItem.countFirstVal)) || isValueEmpty(commItem.countFirstVal) ? $n(parseInt(commItem.countFirstVal)) : parseInt('0')"
                            @change="val => changeFirstYearVal(commItem, val, 'commService')"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d{0,9}$')"
                            @blur="inputFixed(commItem, 'countFirstVal', commItem.countFirstVal, 2)"
                            :disabled="!attributeStatus"
                        >
                        </b-form-input>
                      </div>
                    </td>
                    <td>
                      <div class="bl-td-xx-with-input">
                        <span class="tempText">{{ commItem.secondVal }}</span>
                        <b-form-input
                            class="text-right inputCommService"
                            :value="isNaN(parseFloat(commItem.countSecondVal))===true || isFinite(parseFloat(commItem.countSecondVal)) || isValueEmpty(commItem.countSecondVal) ? $n(parseInt(commItem.countSecondVal)) : parseInt('0')"
                            @change="val => changeSecondYearVal(commItem, val, 'commService')"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d{0,9}$')"
                            @blur="inputFixed(commItem, 'countSecondVal', commItem.countSecondVal, 2)"
                            :disabled="!attributeStatus"
                        >
                        </b-form-input>
                      </div>
                    </td>
                    <td>
                      <div class="bl-td-xx-with-input">
                        <span class="tempText">{{ commItem.thirdVal }}</span>
                        <b-form-input
                            class="text-right inputCommService"
                            :value="isNaN(parseFloat(commItem.countThirdVal))===true || isFinite(parseFloat(commItem.countThirdVal)) || isValueEmpty(commItem.countThirdVal) ? $n(parseInt(commItem.countThirdVal)) : parseInt('0')"
                            @change="val => changeThirdYearVal(commItem, val, 'commService')"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d{0,9}$')"
                            @blur="inputFixed(commItem, 'countThirdVal', commItem.countThirdVal, 2)"
                            :disabled="!attributeStatus"
                        >
                        </b-form-input>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr class="bg-tr1">
                  <td></td>
                  <td><b>Итого</b></td>
                  <td><b>{{ $n(totalValCount, 'decimal') }}</b></td>
                  <td><b>{{ $n(Math.round(totalFirstCount))}}</b></td>
                  <td><b>{{ $n(Math.round(totalSecondCount))}}</b></td>
                  <td><b>{{ $n(Math.round(totalThirdCount))}}</b></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <template #modal-footer>
            <div>
              <b-button variant="success" size="sm" class="mr-2" @click="saveUtilityServices(true)" :disabled="!attributeStatus">Сохранить</b-button>
              <b-button variant="secondary" size="sm" @click="saveUtilityServices(false)">Отменить</b-button>
            </div>
          </template>
        </b-modal>

        <!-- Модальное окном для 159 формы -->
        <b-modal
                v-model="modalOtherServicesVisible"
                modal-class="modal-table add-abp"
                size="xl"
                title=""
                centered
        >
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <div class="title">Оплата прочих услуг и работ:</div>
                <i class="icon icon-close" @click="close()"></i>
            </template>
            <div class="table-container">
                <div class="b-table-sticky-header table-responsive-true">
                    <div v-if="isWarning" class="warning-message">{{ warningTxt }}</div>
                    <table class="table b-table table-bordered b-table-no-border-collapse">
                        <thead>
                        <tr>
                            <th class="utilityServices">№</th>
                            <th class="utilityServices">Наименование ком.услуг</th>
                            <th class="utilityServices">Расчетные данные за {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-1 }} год</th>
                            <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear) }}</th>
                            <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+1 }}</th>
                            <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+2 }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(commGarbageItem, commGarbageIndex) of form159">
                          <tr :key="'commGarbageIndex'+commGarbageIndex" class="trTdCommService">
                              <td>{{ commGarbageItem.index }}</td>
                              <td>{{ commGarbageItem.nameRu }}</td>
                              <td>
                                  <b-form-input
                                          class="text-right inputCommService"
                                          :value="$n(parseInt(commGarbageItem.value))"
                                          readonly
                                  >
                                  </b-form-input>
                              </td>
                              <td>
                                  <div class="bl-td-xx-with-input">
                                      <span class="tempText">{{ commGarbageItem.firstVal }}</span>
                                      <b-form-input
                                              class="text-right inputCommService"
                                              :value="isNaN(parseFloat(commGarbageItem.countFirstVal))===true || isFinite(parseFloat(commGarbageItem.countFirstVal)) || isValueEmpty(commGarbageItem.countFirstVal) ? $n(Math.round(commGarbageItem.countFirstVal)) : parseInt('0')"
                                              @change="val => changeFirstYearVal(commGarbageItem, val, 'garbageService')"
                                              @keyup.enter.exact="keyup13"
                                              @keypress="keyPress($event, '^\\d{0,9}$')"
                                              @blur="inputFixed(commGarbageItem, 'countFirstVal', commGarbageItem.countFirstVal, 2)"
                                              :disabled="!attributeStatus"
                                      >
                                      </b-form-input>
                                  </div>
                              </td>
                              <td>
                                  <div class="bl-td-xx-with-input">
                                      <span class="tempText">{{ commGarbageItem.secondVal }}</span>
                                      <b-form-input
                                              class="text-right inputCommService"
                                              :value="isNaN(parseFloat(commGarbageItem.countSecondVal))===true || isFinite(parseFloat(commGarbageItem.countSecondVal)) || isValueEmpty(commGarbageItem.countSecondVal) ? $n(Math.round(commGarbageItem.countSecondVal)) : parseInt('0')"
                                              @change="val => changeSecondYearVal(commGarbageItem, val, 'garbageService')"
                                              @keyup.enter.exact="keyup13"
                                              @keypress="keyPress($event, '^\\d{0,9}$')"
                                              @blur="inputFixed(commGarbageItem, 'countSecondVal', commGarbageItem.countSecondVal, 2)"
                                              :disabled="!attributeStatus"
                                      >
                                      </b-form-input>
                                  </div>
                              </td>
                              <td>
                                  <div class="bl-td-xx-with-input">
                                      <span class="tempText">{{ commGarbageItem.thirdVal }}</span>
                                      <b-form-input
                                              class="text-right inputCommService"
                                              :value="isNaN(parseFloat(commGarbageItem.countThirdVal))===true || isFinite(parseFloat(commGarbageItem.countThirdVal)) || isValueEmpty(commGarbageItem.countThirdVal) ? $n(Math.round(commGarbageItem.countThirdVal)) : parseInt('0')"
                                              @change="val => changeThirdYearVal(commGarbageItem, val, 'garbageService')"
                                              @keyup.enter.exact="keyup13"
                                              @keypress="keyPress($event, '^\\d{0,9}$')"
                                              @blur="inputFixed(commGarbageItem, 'countThirdVal', commGarbageItem.countThirdVal, 2)"
                                              :disabled="!attributeStatus"
                                      >
                                      </b-form-input>
                                  </div>
                              </td>
                          </tr>
                          <template v-if="commGarbageIndex < 1">
                              <tr :key="'commGarbageIndex2'+commGarbageIndex" class="bl-tr-sp1">
                                <td colspan="6">в том числе:</td>
                              </tr>
                            </template>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>

            <template #modal-footer>
                <div>
                    <b-button variant="success" size="sm" class="mr-2" @click="saveOtherServices(true)" :disabled="!attributeStatus">Сохранить</b-button>
                    <b-button variant="secondary" size="sm" @click="saveOtherServices(false)">Отменить</b-button>
                </div>
            </template>
        </b-modal>

      <!-- Модальное окном для 144 формы -->
      <b-modal
          v-model="modalFuelServicesVisible"
          modal-class="modal-table add-abp"
          size="xl"
          title=""
          centered
      >
        <template #modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <div class="title">Приобретение топлива, горюче-смазочных материалов:</div>
          <i class="icon icon-close" @click="close()"></i>
        </template>
        <div class="table-container">
          <div class="b-table-sticky-header table-responsive-true">
            <div v-if="isWarning" class="warning-message">{{ warningTxt }}</div>
            <table class="table b-table table-bordered b-table-no-border-collapse">
              <thead>
              <tr>
                <th class="utilityServices">№</th>
                <th class="utilityServices">Наименование ком.услуг</th>
                <th class="utilityServices">РАСЧЕТНЫЕ ДАННЫЕ ИЗ ФОРМ РАСЧЕТОВ ЗА {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)-1 }} год</th>
                <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear) }}</th>
                <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+1 }}</th>
                <th class="utilityServices">Прогноз {{ isNaN(parseInt(this.chosenYear)) ? '' : parseInt(this.chosenYear)+2 }}</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(fuelTypesItem, fuelTypesIndex) of form144">
                <tr :key="'fuelTypesIndex'+fuelTypesIndex" class="trTdCommService">
                  <td>{{ fuelTypesItem.index }}</td>
                  <td>{{ fuelTypesItem.nameRu }}</td>
                  <td>
                    <b-form-input
                        class="text-right inputCommService"
                        :value="$n(parseInt(fuelTypesItem.value))"
                        readonly
                    >
                    </b-form-input>
                  </td>
                  <td>
                    <div class="bl-td-xx-with-input">
                      <span class="tempText">{{ fuelTypesItem.valOne }}</span>
                      <b-form-input
                          class="text-right inputCommService"
                          :value="isNaN(parseFloat(fuelTypesItem.countFirstVal))===true || isFinite(parseFloat(fuelTypesItem.countFirstVal)) || isValueEmpty(fuelTypesItem.countFirstVal) ? $n(parseInt(fuelTypesItem.countFirstVal)) : parseInt('0')"
                          @change="val => changeFirstYearVal(fuelTypesItem, val, 'fuelTypes')"
                          @keyup.enter.exact="keyup13"
                          @keypress="keyPress($event, '^\\d{0,9}$')"
                          @blur="inputFixed(fuelTypesItem, 'countFirstVal', fuelTypesItem.countFirstVal, 2)"
                          :disabled="!attributeStatus"
                      >
                      </b-form-input>
                    </div>
                  </td>
                  <td>
                    <div class="bl-td-xx-with-input">
                      <span class="tempText">{{ fuelTypesItem.secondVal }}</span>
                      <b-form-input
                          class="text-right inputCommService"
                          :value="isNaN(parseFloat(fuelTypesItem.countSecondVal))===true || isFinite(parseFloat(fuelTypesItem.countSecondVal)) || isValueEmpty(fuelTypesItem.countSecondVal) ? $n(parseInt(fuelTypesItem.countSecondVal)) : parseInt('0')"
                          @change="val => changeSecondYearVal(fuelTypesItem, val, 'fuelTypes')"
                          @keyup.enter.exact="keyup13"
                          @keypress="keyPress($event, '^\\d{0,9}$')"
                          @blur="inputFixed(fuelTypesItem, 'countSecondVal', fuelTypesItem.countSecondVal, 2)"
                          :disabled="!attributeStatus"
                      >
                      </b-form-input>
                    </div>
                  </td>
                  <td>
                    <div class="bl-td-xx-with-input">
                      <span class="tempText">{{ fuelTypesItem.thirdVal }}</span>
                      <b-form-input
                          class="text-right inputCommService"
                          :value="isNaN(parseFloat(fuelTypesItem.countThirdVal))===true || isFinite(parseFloat(fuelTypesItem.countThirdVal)) || isValueEmpty(fuelTypesItem.countThirdVal) ? $n(parseInt(fuelTypesItem.countThirdVal)) : parseInt('0')"
                          @change="val => changeThirdYearVal(fuelTypesItem, val, 'garbageService')"
                          @keyup.enter.exact="keyup13"
                          @keypress="keyPress($event, '^\\d{0,9}$')"
                          @blur="inputFixed(fuelTypesItem, 'countThirdVal', fuelTypesItem.countThirdVal, 2)"
                          :disabled="!attributeStatus"
                      >
                      </b-form-input>
                    </div>
                  </td>
                </tr>
              </template>
              <tr class="bg-tr1">
                <td></td>
                <td><b>Итого</b></td>
                <td><b>{{ $n(totalValCountSpf144, 'decimal') }}</b></td>
                <td><b>{{ $n(Math.round(totalFirstCountSpf144))}}</b></td>
                <td><b>{{ $n(Math.round(totalSecondCountSpf144))}}</b></td>
                <td><b>{{ $n(Math.round(totalThirdCountSpf144))}}</b></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <template #modal-footer>
          <div>
            <b-button variant="success" size="sm" class="mr-2" @click="saveFuelTypesServices(true)" :disabled="!attributeStatus">Сохранить</b-button>
            <b-button variant="secondary" size="sm" @click="saveFuelTypesServices(false)">Отменить</b-button>
          </div>
        </template>
      </b-modal>

      <b-modal id="selectbp-modal"
               v-model="modalSpfForm"
               size="lg"
               centered
               modal-class="multiselect-modal multiselect-add-modal"
      >
          <template #modal-header="{ close }">
              <div class="title">Добавить специфику:</div>
              <i class="icon icon-close" @click="close()"></i>
          </template>
          <div class="card-modal-multiselect">
              <b-input-group size="sm" class="mb-2 search-in-modal">
                  <b-form-input type="search" v-model="spfListVal" placeholder="Выберите программу" class="bp-input" @keyup="onSearchSpf"></b-form-input>
              </b-input-group>
              <div class="card-modal-multiselect-content">
                  <div class="table-container">
                      <div class="b-table-sticky-header table-responsive-true">
                          <b-form-group>
                          <table class="table b-table table-bordered b-table-no-border-collapse">
                              <thead>
                              <tr>
                                  <th style="padding-left: 14px;">
                                      <b-form-checkbox
                                          v-model="selectAllSpf"
                                          @change="e => checkboxSelectAllSpf(e)"
                                          :value="true"
                                          :unchecked-value="false" />
                                  </th>
                                  <th>КОД</th>
                                  <th>НАИМЕНОВАНИЕ</th>
                              </tr>
                              </thead>
                              <tbody>
                              <template v-for="(spfItem, spfIndex) of spfList">
                                  <tr :key="'spfItem'+spfIndex">
                                      <td>
                                          <b-form-checkbox
                                          v-model="spfItem.check" @change="onChecked($event)" @input="e => { onInput(e) }">
                                          </b-form-checkbox>
                                      </td>
                                      <td>{{ spfItem.spf }}</td>
                                      <td>{{ spfItem.nameRu }}</td>
                                  </tr>
                              </template>
                              </tbody>
                          </table>
                          </b-form-group>
                      </div>
                  </div>
              </div>
          </div>
          <template #modal-footer>
              <div>
                  <b-button variant="success" size="sm" class="mr-2" @click="addSpf()">Добавить</b-button>
                  <b-button variant="secondary" size="sm" @click="showModalSpfForm(false)">Отменить</b-button>
              </div>
          </template>
      </b-modal>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import CLimitFilter from "@/modules/budget-limitCosts/limitExpenses/BudgetLimitExpensesFilter.vue";
import store from '../../../services/store';
import {isNull, parseInt} from "lodash";
import VueElementLoading from 'vue-element-loading';

export default {
    name: 'budget-limits',
    components: {
        'c-limit-filter': CLimitFilter,
        'loading': VueElementLoading
    },
    data() {
        return {
            modalFuelTypesServicesVisible: false,
            modalUtilityServicesVisible: false,
            modalOtherServicesVisible: false,
            modalFuelServicesVisible: false,
            check: false,
            showInTop: false,
            checkButtonSpf: 0,
            checkCountSpf: 0,
            checkRow: false,
            checkRowButtonSpf: 0,
            checkRowCount: 0,
            loading: false,
            modalSpfForm: false,
            attributeStatus: true,
            selectAllSpf: false,
            selectAllRows: false,
            curYear: null,
            currentRegion: null,
            regionName: null,
            funcGroup: null,
            curAbp: null,
            curProg: null,
            curSubProg: null,
            chosenYear: null,
            spfListVal: null,
            excludedSpfList: null,
            fullInformationListArr: [],
            budgetFactPlanArr: [],  // ------основной массив
            budgetFactPlanArrCloneInitVersion: [],  //--клон основного массива
            budgetFactPlanArrForCheckingShr: [],  //--клон основного массива для проверки ШР
            spfList: [],
            spfListInit: [],
            commServicesArr: [],
            limitsFormCommServicesArr: [],
            limitFormGarbageServicesArr: [],
            limitFormFuelTypesArr: [],
            spfRemovedFromMainList: [],
            dirtyForm: false,
            form159: [],
            form144: [],
            modalSeparateSpecifics144: [],
            modalSeparateSpecifics151: [],
            modalSeparateSpecifics159: [],
            warningTxt: 'Лимит по оплате вывоза ТБО превышает лимит по оплате прочих услуг и работ. Проверьте данные.',
            spfSpecificModal: {
                'modalSpecifics': [
                    {
                        spf: parseInt(144),
                        title: ''
                    },
                    {
                        spf: parseInt(151),
                        title: ''
                    },
                    {
                        spf: parseInt(159),
                        title: ''
                    }
                ]
            },
            spf144ObjWithTotalResults: {},
            spf151ObjWithTotalResults: {},
            spf159ObjWithTotalResults: {},
            shrSpfList: [111, 112, 113, 114, 116, 121, 122, 124, 131, 135],
            isShrNull: false,
            shrMainList: []
        }
    },
    async mounted() {
      await this.getObl();
    },
    /**
        Вылавливает изменения в формах. В случае, если изменения были, то браузер должен
        предупреждать об изменениях в формах перед тем как обновить страницу либо покинуть её
     */
    beforeRouteLeave (to, from, next) {
        this.dirtyForm = this.isEqual(this.budgetFactPlanArrCloneInitVersion, this.budgetFactPlanArr);
        if (!this.dirtyForm) {
            next(false)
            window.location = to.path // this is the trick
        } else {
            next()
        }
    },
    created () {
        window.addEventListener('beforeunload', this.beforeWindowUnload)
    },
    beforeDestroy () {
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
    //**************************************************************************************//
    methods: {
      //******Метод отвечает за вылавливание свойства при обновлении страницы***************//
      beforeWindowUnload (e) {
        this.dirtyForm = this.isEqual(this.budgetFactPlanArrCloneInitVersion, this.budgetFactPlanArr);
        // console.log('beforeWindowUnload_this.dirtyForm: ' + JSON.stringify(this.dirtyForm))
        if (!this.dirtyForm) {
            e.preventDefault()
            e.returnValue = ''
        }
      },
      //**************************************************************************************//
      //----Вначале проверяется количество кликов по кнопке "Открыть" через счетчик
      async chgData(data) {
        if (data != null) {
            await this.filterParams(data);
        }
      },

      // ----Извлекаются параметры из меню фильтрации
      async filterParams(data) {
        if (data !== null) {
            this.curHeader = data;
            if ((this.curHeader !== null)
                && (this.curHeader.year !== null)
                && (this.curHeader.gr !== null)
                && (this.curHeader.prg !== null)
                && (this.curHeader.region !== null)
                && (this.curHeader.regionDetails !== null)) {
                this.budgetFactPlanArr = [];
                this.header = {};
                this.currentRegion = this.curHeader.region;
                this.curHeader.region = regionsForMSU(this.curHeader.region);
                this.header = {
                    year: this.curHeader.year,
                    abp: this.curHeader.abp.abp,
                    prg: this.curHeader.prg.prg,
                    ppr: (this.curHeader.pgr === null ? null : this.curHeader.pgr.ppr),
                    curRegion: this.curHeader.region,
                    regDetails: this.curHeader.regionDetails.code
                };
                this.chosenYear = this.header.year;
                this.curYear = this.header.year;
                this.funcGroup = this.curHeader.gr;
                this.curAbp = this.header.abp;
                this.curProg = this.header.prg;
                this.curSubProg = this.header.ppr;
                this.regionName = this.header.regDetails.name_ru;
                //---Извлечение всего списка из таблицы "budget_form_limits" по году---//
                // await this.getListOfFormLimit(this.header.year);
                await this.getIndexOfInflationIndicators(this.header);
                const obl = this.obl + '0000';
                const region = this.currentRegion;
                this.$emit('chgLimitData', this.header, obl, region, this.informationArr);
                this.accessLevel = await this.getUserAccess(this.userId);
                await this.getBudgetVariantsAttribute(this.header);
            }
        }

        function regionsForMSU(region){
            // console.log('region: ' + JSON.stringify(region));
            const regionEnd = '01';
            const sizeOfWord = region.length;
            // console.log('sizeOfWord: ' + sizeOfWord);
            if (!region.endsWith('01')) {
                const startIndx = parseInt(sizeOfWord) - regionEnd.length;
                const firstPart = region.split('', startIndx).join('');
                const secondPart = region.substring(startIndx, region.length);
                const thirdPart = secondPart.replace(secondPart, regionEnd);
                region = firstPart.toString() + thirdPart.toString();
                // console.log('region: ' + JSON.stringify(region));
            }
            return region;
        }
      },

      //----Извлечь список индекса инфляции из БД по каждому году
      async getIndexOfInflationIndicators(header) {
          this.modalSeparateSpecifics144 = [];
          this.modalSeparateSpecifics151 = [];
          this.modalSeparateSpecifics159 = [];
          const spf144 = this.spfSpecificModal.modalSpecifics[0].spf;
          const spf151 = this.spfSpecificModal.modalSpecifics[1].spf;
          const spf159 = this.spfSpecificModal.modalSpecifics[2].spf
          const firstYears = header.year;
          const thirdYears = header.year + 2;
          const initListOfSeparateSpecificForModals = await this.getListOfSpecificAndInflation(firstYears, thirdYears);
          // console.log('initListOfSeparateSpecificForModals: ' + JSON.stringify(initListOfSeparateSpecificForModals));
          //--Создается массив с объектами для формы 144--//
          const modalSeparateSpecifics144 = getSeparateSpecificModalForm(initListOfSeparateSpecificForModals, spf144);
          if (Object.keys(modalSeparateSpecifics144).length!==0) {
            const indicatorDictFirstItemId144 = getOnlyObjects(modalSeparateSpecifics144);  //--Получить уникальные indicatorDictFirstItemId
            this.modalSeparateSpecifics144 = getDetailedArrForThreeYears(indicatorDictFirstItemId144, modalSeparateSpecifics144, firstYears);
          }
          // console.log('this.modalSeparateSpecifics144: ' + JSON.stringify(this.modalSeparateSpecifics144));
          //--Создается массив с объектами для формы 151--//
          const modalSeparateSpecifics151 = getSeparateSpecificModalForm(initListOfSeparateSpecificForModals, spf151);
          if (Object.keys(modalSeparateSpecifics151).length!==0) {
            const indicatorDictFirstItemId151 = getOnlyObjects(modalSeparateSpecifics151);  //--Получить уникальные indicatorDictFirstItemId
            this.modalSeparateSpecifics151 = getDetailedArrForThreeYears(indicatorDictFirstItemId151, modalSeparateSpecifics151, firstYears);
          }
          // console.log('this.modalSeparateSpecifics151: ' + JSON.stringify(this.modalSeparateSpecifics151));
          //--Создается массив с объектами для формы 159--//
          const modalSeparateSpecifics159 = getSeparateSpecificModalForm(initListOfSeparateSpecificForModals, spf159);
          if (Object.keys(modalSeparateSpecifics159).length!==0) {
            const indicatorDictFirstItemId159 = getOnlyObjects(modalSeparateSpecifics159);  //--Получить уникальные indicatorDictFirstItemId
            this.modalSeparateSpecifics159 = getDetailedArrForThreeYears(indicatorDictFirstItemId159, modalSeparateSpecifics159, firstYears);
          }
          // console.log('this.modalSeparateSpecifics159: ' + JSON.stringify(this.modalSeparateSpecifics159));

          await this.getFormLimitList(header);

          function getSeparateSpecificModalForm(initListOfSeparateSpecificForModals, spf){
            if (initListOfSeparateSpecificForModals.length>0){
              for (const initItem of initListOfSeparateSpecificForModals){
                if (initItem.spf === spf){
                  return specifyYearInArr(initItem);
                }
              }
            }
            return {};
          } //--Извлечь сведения по конкретному spf

          function specifyYearInArr(mainObj){
            const mainArr = [];
            if (Object.keys(mainObj).length !== 0 && mainObj.inputFormData && mainObj.inputFormData.length>0){
              for (const arr of mainObj.inputFormData){
                if (arr.date!=='' && arr.date!==null){
                  const obj = {
                    spf: mainObj.spf,
                    indicatorId: mainObj.dictIndicatorId.id,
                    title: mainObj.dictIndicatorId.name_ru,
                    inputFormDataId: arr.id,
                    indicatorDictFirstItemId: arr.indicatorDictFirstItemId,
                    inputFormIndicatorId: arr.inputFormIndicatorId,
                    year: new Date(arr.date).getFullYear(),
                    value: arr.value
                  }
                  mainArr.push(obj);
                }
              }
            }
            return mainArr;
          } //--Создать единый массив для последующей переделки

          function getOnlyObjects(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
              const x = enteredArr.find(item => item.indicatorDictFirstItemId === current.indicatorDictFirstItemId);
              if (!x) {
                return enteredArr.concat([current]);
              } else {
                return enteredArr;
              }
            }, []);
            return filteredObjectArr;
          } //--Удалить дубликаты по indicatorDictFirstItemId

          function getDetailedArrForThreeYears(indicatorDictFirstItemId151, modalSeparateSpecifics151, year){
            const arr = [];
            if (indicatorDictFirstItemId151.length>0 && modalSeparateSpecifics151.length>0){
              for (const indicator of indicatorDictFirstItemId151){
                let firstYearVal = 0;
                let secondYearVal = 0;
                let thirdYearVal = 0;

                for (const item of modalSeparateSpecifics151){
                  if (indicator.indicatorDictFirstItemId === item.indicatorDictFirstItemId){
                    if (parseInt(item.year) === parseInt(year)) {
                      firstYearVal = item.value;
                    } else if (parseInt(item.year) === parseInt(year+1)) {
                      secondYearVal = item.value;
                    } else if (parseInt(item.year) === parseInt(year+2)) {
                      thirdYearVal = item.value;
                    }
                  }
                }
                const obj = {
                  spf: indicator.spf,
                  indicatorId: indicator.indicatorId,
                  title: indicator.title,
                  inputFormDataId: indicator.inputFormDataId,
                  indicatorDictFirstItemId: indicator.indicatorDictFirstItemId,
                  inputFormIndicatorId: indicator.inputFormIndicatorId,
                  firstYear: parseInt(year),
                  valOne: firstYearVal,
                  secondYear: parseInt(year+1),
                  valTwo: secondYearVal,
                  thirdYear: parseInt(year+2),
                  valThree: thirdYearVal
                }
                arr.push(obj);
              }
            }
            return arr;
          } //--Группирует значения по конкретному году
      },

      //---Извлекается список специфик и инфляции
      async getListOfSpecificAndInflation(firstYears, thirdYears){
          /*
              Извлекаются все специфики согласно параметрам
          * */
          const regionkato = this.getRegionForKato(this.header.regDetails);
          const region = this.currentRegion;
          const response = await fetch('/api/new/specifics/modal_forms/'+firstYears+'/'+thirdYears+'/'+regionkato+'/'+region);
          const items = await response.json();
          // console.log('specificsItems: ' + JSON.stringify(items));
          const specificArr = [];
          const fullSpecificList = [];
          let inflation = {};
          if (items.length > 0){
              for (const item of items){
                  if (item.spf === null){
                      inflation = item;
                  } else {
                      specificArr.push(item);
                  }
                  fullSpecificList.push(item);
              }
          }
          return fullSpecificList;
      },

      //---Извлечь список специфик с ШР из БД
      async getShRList(){
        const header = this.header;
        const region = this.currentRegion;
        try {
          // console.log('region: ' + region);
          const url = '/api/request-form/shr-list/'+header.abp+'/'+header.prg+'/'+header.ppr+'/'+header.year+'/'+region;
          const response = await fetch(url);
          const itemsArr = await response.json();
          // console.log('getShRList: ' + JSON.stringify(itemsArr));
          return itemsArr;
        } catch (error) {
          this.makeToast('', 'По данным параметрам отсутствуют записи в ШР', error.toString());
        }
        return [];
      },
      //---Получить данные с ШР если они есть и добавить в гриду для уведомления
      setInitValueShrInWarning(itemsArr, mainObj){
            if (itemsArr.length>0){
                for (const shr of itemsArr){
                    if (mainObj.spf===shr.spf && shr.year===this.header.year){
                        mainObj.forecastYearPlusOneShr = shr.value;
                    } else if (mainObj.spf === shr.spf && shr.year===this.header.year+1){
                        mainObj.twoYearPlusForecastShr = shr.value;
                    } else if (mainObj.spf === shr.spf && shr.year===this.header.year+2){
                        mainObj.threeYearPlusForecastShr = shr.value;
                    }
                }
            }
            return mainObj;
        },
      //---Извлечь список специфик с ШР
      async updateDataFromShR() {
          const itemsShR = this.shrMainList;
          const updateResults = this.joinShRWithMainArr(itemsShR);
          if (updateResults){
              this.makeToastWarn('warning', 'Обновление данных с ШР', 'Данные обновлены');
          }
      },
      //---Объединить данные с ШР с массивом основной гриды
      joinShRWithMainArr(itemsShR){
          if (itemsShR.length>0 && this.budgetFactPlanArr.length>0){
              // console.log('itemsShR: ' + JSON.stringify(itemsShR));
              for (const factPlan of this.budgetFactPlanArr){
                  for (const item of itemsShR){
                      if (factPlan.spf === item.spf && item.year===this.header.year){
                          factPlan.forecastYearPlusOne = item.value;
                          factPlan.shrOneValue = item.value;
                      } else if (factPlan.spf === item.spf && item.year===this.header.year+1){
                          factPlan.twoYearPlusForecast = item.value;
                          factPlan.shrTwoValue = item.value;
                      } else if (factPlan.spf === item.spf && item.year===this.header.year+2){
                          factPlan.threeYearPlusForecast = item.value;
                          factPlan.shrThreeValue = item.value;
                      }
                  }
              }
              return true;
          }
          return false;
      },

      changeCellOneTimeExpenses(item, oneTimeExpenses){
        item.oneTimeExpenses = parseFloat(oneTimeExpenses.replace(/\s+/g, '')).toFixed(2);
        item.refinedPlan = Math.round((parseFloat(item.planSum ? item.planSum : 0) + parseFloat(item.rbTransfert ? item.rbTransfert : 0) + parseFloat(item.correctedPlan ? item.correctedPlan : 0)) - parseFloat(item.oneTimeExpenses ? item.oneTimeExpenses : 0));
        if (parseInt(this.spfSpecificModal.modalSpecifics[0].spf) !== parseInt(item.spf) && parseInt(this.spfSpecificModal.modalSpecifics[1].spf) !== parseInt(item.spf) && parseInt(this.spfSpecificModal.modalSpecifics[2].spf) !== parseInt(item.spf)) {
            this.countForecastsByRefinedPlan(item);
        }
      },

      changeCellRbTransfert(item, rbTransfert){
        item.rbTransfert = parseFloat(rbTransfert.replace(/\s+/g, '')).toFixed(2);
        item.refinedPlan = Math.round((parseFloat(item.planSum ? item.planSum : 0) + parseFloat(item.rbTransfert ? item.rbTransfert : 0) + parseFloat(item.correctedPlan ? item.correctedPlan : 0)) - parseFloat(item.oneTimeExpenses ? item.oneTimeExpenses : 0));
          if (parseInt(this.spfSpecificModal.modalSpecifics[0].spf) !== parseInt(item.spf) && parseInt(this.spfSpecificModal.modalSpecifics[1].spf) !== parseInt(item.spf) && parseInt(this.spfSpecificModal.modalSpecifics[2].spf) !== parseInt(item.spf)) {
              this.countForecastsByRefinedPlan(item);
          }
      },

      changeCellCorrectedPlan(item, correctedPlan){
        item.correctedPlan = parseFloat(correctedPlan.replace(/\s+/g, '')).toFixed(2);
        item.refinedPlan = Math.round((parseFloat(item.planSum ? item.planSum : 0) + parseFloat(item.rbTransfert ? item.rbTransfert : 0) + parseFloat(item.correctedPlan ? item.correctedPlan : 0)) - parseFloat(item.oneTimeExpenses ? item.oneTimeExpenses : 0));
          if (parseInt(this.spfSpecificModal.modalSpecifics[0].spf) !== parseInt(item.spf) && parseInt(this.spfSpecificModal.modalSpecifics[1].spf) !== parseInt(item.spf) && parseInt(this.spfSpecificModal.modalSpecifics[2].spf) !== parseInt(item.spf)) {
              this.countForecastsByRefinedPlan(item);
          }
      },
      //--В Т.Ч. РАЗОВЫЕ РАСХОДЫ
      changeCellExpenses(item, expensesInExcessOfParameters){
        item.expensesInExcessOfParameters = Math.round(parseInt(expensesInExcessOfParameters.replace(/\s+/g, '')));
      },
      //--В Т.Ч. РАСХОДЫ, УЧТЕННЫЕ СВЕРХ ПАРАМЕТРОВ
      changeCellForecast(item, forecastYearPlusOne) {
        item.forecastYearPlusOne = Math.round(forecastYearPlusOne.replace(/\s+/g, ''));
        item.percentOfGrowth = !isNaN(item.forecastYearPlusOne) && parseFloat(item.refinedPlan)!==parseFloat('0') ? parseFloat((parseFloat(item.forecastYearPlusOne) / parseFloat(item.refinedPlan)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);

        if (item.secondYearVal!==null && item.secondYearVal!=='' && item.secondYearVal!==0) {
          const countTwo = Math.round(parseFloat(item.forecastYearPlusOne) + (parseFloat(item.forecastYearPlusOne) * parseFloat(item.secondYearVal)) / parseFloat(100));
          item.twoYearPlusForecast = Math.round(countTwo);
          item.twoYearPlusPercent = !isNaN(item.twoYearPlusForecast) && parseFloat(item.forecastYearPlusOne) !== parseFloat('0') ? parseFloat((parseFloat(item.twoYearPlusForecast) / parseFloat(item.forecastYearPlusOne)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
        } else {
          item.twoYearPlusForecast = item.forecastYearPlusOne;
          item.twoYearPlusPercent = !isNaN(item.twoYearPlusForecast) && parseFloat(item.forecastYearPlusOne) !== parseFloat('0') ? parseFloat((parseFloat(item.twoYearPlusForecast) / parseFloat(item.forecastYearPlusOne)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
        }

        if (item.thirdYearVal!==null && item.thirdYearVal!=='' && item.thirdYearVal!==0) {
          const countThree = parseFloat(item.twoYearPlusForecast) + (parseFloat(item.twoYearPlusForecast) * parseFloat(item.thirdYearVal)) / parseFloat(100);
          item.threeYearPlusForecast = Math.round(countThree);
          item.threeYearPlusPercent = !isNaN(item.threeYearPlusForecast) && parseFloat(item.twoYearPlusForecast) !== parseFloat('0') ? parseFloat((parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
        } else {
          item.threeYearPlusForecast = item.twoYearPlusForecast;
          item.threeYearPlusPercent = !isNaN(item.threeYearPlusForecast) && parseFloat(item.twoYearPlusForecast) !== parseFloat('0') ? parseFloat((parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
        }
      },
      changeCellForecastTwo(item, twoYearPlusForecast) {
        item.twoYearPlusForecast = Math.round(twoYearPlusForecast.replace(/\s+/g, ''));
        item.twoYearPlusPercent = !isNaN(item.twoYearPlusForecast) && parseFloat(item.forecastYearPlusOne)!==parseFloat('0') ? parseFloat((parseFloat(item.twoYearPlusForecast) / parseFloat(item.forecastYearPlusOne)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);

        if (item.thirdYearVal!==null && item.thirdYearVal!=='' && item.thirdYearVal!==0) {
          const countThree = parseFloat(item.twoYearPlusForecast) + (parseFloat(item.twoYearPlusForecast) * parseFloat(item.thirdYearVal)) / parseFloat(100);
          item.threeYearPlusForecast = Math.round(countThree);
          item.threeYearPlusPercent = !isNaN(item.threeYearPlusForecast) && parseFloat(item.twoYearPlusForecast) !== parseFloat('0') ? parseFloat((parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
        } else {
          item.threeYearPlusForecast = item.twoYearPlusForecast;
          item.threeYearPlusPercent = !isNaN(item.threeYearPlusForecast) && parseFloat(item.twoYearPlusForecast) !== parseFloat('0') ? parseFloat((parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
        }
      },
      changeCellForecastThree(item, threeYearPlusForecast){
        item.threeYearPlusForecast = Math.round(threeYearPlusForecast.replace(/\s+/g, ''));
        item.threeYearPlusPercent = !isNaN(item.threeYearPlusForecast) && parseFloat(item.twoYearPlusForecast)!==parseFloat('0') ? parseFloat((parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
      },

      countForecastsByRefinedPlan(item){
        const shr = this.shrSpfList.find(el => parseInt(el) === parseInt(item.spf));
        if (!shr){
          const first = (item.firstYearVal/100)+1;
          const second = (item.secondYearVal/100)+1;
          const third = (item.thirdYearVal/100)+1;

          item.forecastYearPlusOne = Math.round(parseFloat(item.refinedPlan)*parseFloat(first));
          item.percentOfGrowth = !isNaN(item.forecastYearPlusOne) && parseFloat(item.refinedPlan)!==parseFloat('0') ? parseFloat((parseFloat(item.forecastYearPlusOne) / parseFloat(item.refinedPlan)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);

          if (item.secondYearVal!==null && item.secondYearVal!=='' && item.secondYearVal!==0) {
            item.twoYearPlusForecast = Math.round(parseFloat(item.forecastYearPlusOne) * parseFloat(second));
            item.twoYearPlusPercent = !isNaN(item.twoYearPlusForecast) && parseFloat(item.forecastYearPlusOne) !== parseFloat('0') ? parseFloat((parseFloat(item.twoYearPlusForecast) / parseFloat(item.forecastYearPlusOne)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
          } else {
            item.twoYearPlusForecast = item.forecastYearPlusOne;
            item.twoYearPlusPercent = !isNaN(item.twoYearPlusForecast) && parseFloat(item.forecastYearPlusOne) !== parseFloat('0') ? parseFloat((parseFloat(item.twoYearPlusForecast) / parseFloat(item.forecastYearPlusOne)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
          }

          if (item.thirdYearVal!==null && item.thirdYearVal!=='' && item.thirdYearVal!==0) {
            item.threeYearPlusForecast = Math.round(parseFloat(item.twoYearPlusForecast) * parseFloat(third));
            item.threeYearPlusPercent = !isNaN(item.threeYearPlusForecast) && parseFloat(item.twoYearPlusForecast) !== parseFloat('0') ? parseFloat((parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
          } else {
            item.threeYearPlusForecast = item.twoYearPlusForecast;
            item.threeYearPlusPercent = !isNaN(item.threeYearPlusForecast) && parseFloat(item.twoYearPlusForecast) !== parseFloat('0') ? parseFloat((parseFloat(item.threeYearPlusForecast) / parseFloat(item.twoYearPlusForecast)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
          }
        }
      },

      async showModalSpfForm(show) {
        this.modalSpfForm = show;
        if (show) {
          this.spfList = await this.getListOfSpfNames();
          this.spfListVal = null;
        }
      },

      // -------Метод для извлечения области и региона-------//
      async getObl() {    // -----Извлекается регион и область из глобальных настроек пользователя
        try {
          const instanceCode = store.state._instanceCode!='' ? store.state._instanceCode : '55';
          await fetch('/api-py/get-obl-limit/' + instanceCode)
              .then(response => response.json())
              .then(json => {
                this.obl = json.obl;
                // region = json.region;
              });
        } catch (error) {
          this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
        }
      },

      async getFormLimitList(header){
        this.loading = true;
        this.spfList = [];
        this.shrMainList = [];
        let regionKato = this.getRegionForKato(this.header.regDetails);
            regionKato = regionKato && regionKato!=='' ? regionKato : '000000';
        // -----Основной метод для извлечения всех справочников для последующего отображения в грид таблице---//
        const obl = this.obl + '0000';
        const region = this.currentRegion;
        const abp = header.abp!=null ? this.padLeadingZeros(header.abp, 3): 0;
        const prg = header.prg!=null ? this.padLeadingZeros(header.prg, 3): 0;
        this.abp = abp;
        this.prg = prg;
        this.ppr = header.ppr;
        const abpVal = header.abp != null ? header.abp : 0;
        const prgVal = header.prg != null ? header.prg : 0;
        const pprVal = header.ppr != null ? header.ppr : 0;
        let items = [];
        console.log('year: ' + this.curYear + ' region: ' + region + ' abp: ' + abpVal + ' prg: ' + prgVal + ' ppr: ' + pprVal + ' regionKato: ' + regionKato);
        try {
            const response = await fetch('/api/budget-form-limit/new/limit/' + this.curYear + '/' + region + '/' + obl + '/' + abpVal + '/' + prgVal + '/' + pprVal + '/' + regionKato);
            items = await response.json();
            // console.log('FormLimitItems: ' + JSON.stringify(items));
            await this.generateMainArr(items, this.curYear);
            this.loading = false;

            this.shrMainList = await this.getShRList();
            const checkNewShrData = this.checkNewShrData(this.shrMainList, this.budgetFactPlanArr);
            if (checkNewShrData){
                this.showInTop = true;
            } else {
                this.showInTop = false;
            }
        } catch (error) {
            this.shrMainList = await this.getShRList();
            const checkNewShrData = this.checkNewShrInSpecifics(this.shrMainList);
            if (checkNewShrData){
              this.showInTop = true;
            } else {
              this.showInTop = false;
            }
            this.makeToast('Внимание', 'По данному запросу ничего не найдено ', error.toString());
            this.loading = false;
        }
      },

      checkNewShrData(shrMainList, budgetFactPlanArr){
          if (shrMainList.length>0 && budgetFactPlanArr.length>0){
              for (const factPlan of budgetFactPlanArr){
                  for (const item of shrMainList){
                      if (factPlan.spf === item.spf && item.year===this.header.year && parseInt(factPlan.forecastYearPlusOne) !== parseInt(item.value)){
                          return true;
                      } else if (factPlan.spf === item.spf && item.year===this.header.year+1 && parseInt(factPlan.twoYearPlusForecast) !== parseInt(item.value)){
                          return true;
                      } else if (factPlan.spf === item.spf && item.year===this.header.year+2 && parseInt(factPlan.threeYearPlusForecast) !== parseInt(item.value)){
                          return true;
                      }
                  }
              }
          }
          return false;
      },

      checkNewShrInSpecifics(shrMainList){
        if (this.spfListInit && this.spfListInit.length>0){
          for (const spfInit of this.spfListInit){
            for (const item of shrMainList){
              if (spfInit.spf === item.spf && item.year===this.header.year){
                return true;
              } else if (spfInit.spf === item.spf && item.year===this.header.year+1){
                return true;
              } else if (spfInit.spf === item.spf && item.year===this.header.year+2){
                return true;
              }
            }
          }
        }
        return false;
      },

      async generateMainArr(arrList, year) {
        this.budgetFactPlanArr = [];
        this.budgetFactPlanArrCloneInitVersion = [];
        this.budgetFactPlanArrForCheckingShr = [];
        let arrClone = [];
        if (arrList.length > 0) {
            for (const arrItem of arrList) {
                let mainObj = this.generateMainObj(arrItem, year);
                mainObj = this.setShrSpfValInList(mainObj);
                mainObj = isNaNorIsFiniteValues(mainObj);
                mainObj = getPercentageOfForecasts(mainObj);
                mainObj = this.setInitValueShrInWarning(this.shrMainList, mainObj);
                this.budgetFactPlanArr.push(mainObj);
                arrClone.push(mainObj); //---Создаю клон основного массива
            }
            this.budgetFactPlanArr = this.budgetFactPlanArr.sort((a, b) => a.spf - b.spf);
            arrClone = arrClone.sort((a, b) => a.spf - b.spf);
            this.budgetFactPlanArrCloneInitVersion = JSON.parse(JSON.stringify(arrClone));
            this.budgetFactPlanArrForCheckingShr = JSON.parse(JSON.stringify(arrClone));  //--Проверка Shr (есть ли shr среди новых специфик)
        }
        this.spfList = await this.getListOfSpfNames();
        this.budgetFactPlanArrForCheckingShr = this.combineBudgetFactListWithSpfList(this.budgetFactPlanArrForCheckingShr, this.spfList);
        await this.checkShrInBudgetFactPlanArrForCheckingShr(this.budgetFactPlanArrForCheckingShr);

        function getPercentageOfForecasts(el) {
            // ---------Вычисление в столбце "% роста - 2024"
            el.percentOfGrowth = !isNaN(el.forecastYearPlusOne) && parseFloat(el.refinedPlan) !== parseFloat('0') ? parseFloat((parseFloat(el.forecastYearPlusOne) / parseFloat(el.refinedPlan)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
            // ---------Вычисление в столбце "% роста - 2025 год"
            el.twoYearPlusPercent = !isNaN(el.twoYearPlusForecast) && parseFloat(el.forecastYearPlusOne) !== parseFloat('0') ? parseFloat((parseFloat(el.twoYearPlusForecast) / parseFloat(el.forecastYearPlusOne)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
            // // ---------Вычисление в столбце "% роста - 2026 год"
            el.threeYearPlusPercent = !isNaN(el.threeYearPlusForecast) && parseFloat(el.twoYearPlusForecast) !== parseFloat('0') ? parseFloat((parseFloat(el.threeYearPlusForecast) / parseFloat(el.twoYearPlusForecast)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
            return el;
        }

        function isNaNorIsFiniteValues(el) {
            el.factSum = !isNaN(el.factSum) ? parseFloat(el.factSum).toFixed(2) : parseFloat('0').toFixed(2);
            el.planSum = !isNaN(el.planSum) ? parseFloat(el.planSum).toFixed(2) : parseFloat('0').toFixed(2);
            el.oneTimeExpenses = !isNaN(el.oneTimeExpenses) ? parseInt(el.oneTimeExpenses) : parseInt('0');
            el.rbTransfert = !isNaN(el.rbTransfert) ? parseInt(el.rbTransfert) : parseInt('0');
            el.correctedPlan = !isNaN(el.correctedPlan) ? parseInt(el.correctedPlan) : parseInt('0');
            el.refinedPlan = !isNaN(el.refinedPlan) ? parseInt(el.refinedPlan) : parseInt('0');
            el.forecastYearPlusOne = !isNaN(el.forecastYearPlusOne) ? parseInt(el.forecastYearPlusOne) : parseInt('0');
            el.expensesInExcessOfParameters = !isNaN(el.expensesInExcessOfParameters) ? parseInt(el.expensesInExcessOfParameters) : parseInt('0');
            el.percentOfGrowth = !isNaN(el.percentOfGrowth) && isFinite(el.percentOfGrowth) ? parseFloat(el.percentOfGrowth) : parseFloat('0').toFixed(2);
            el.twoYearPlusForecast = !isNaN(el.twoYearPlusForecast) ? parseInt(el.twoYearPlusForecast) : parseInt('0');
            el.twoYearPlusPercent = !isNaN(el.twoYearPlusPercent) && isFinite(el.twoYearPlusPercent) ? parseFloat(el.twoYearPlusPercent).toFixed(2) : parseFloat('0').toFixed(2);
            el.threeYearPlusForecast = !isNaN(el.threeYearPlusForecast) ? parseInt(el.threeYearPlusForecast) : parseInt('0');
            el.threeYearPlusPercent = !isNaN(el.threeYearPlusPercent) && isFinite(el.threeYearPlusPercent) ? parseFloat(el.threeYearPlusPercent).toFixed(2) : parseFloat('0').toFixed(2);
            return el;
        }
      },

      combineBudgetFactListWithSpfList(factPlanArr, spfList){
        if (factPlanArr && factPlanArr.length>0 && spfList && spfList.length>0){
          for (const spfItem of spfList){
            const checkingShrInList = this.generateNewObjOnlyForCheckingShrInList(spfItem);
            factPlanArr.push(checkingShrInList);
          }
        }
        return factPlanArr;
      },

      async checkShrInBudgetFactPlanArrForCheckingShr(budgetFactPlanArrForCheckingShr) {
        if (budgetFactPlanArrForCheckingShr && budgetFactPlanArrForCheckingShr.length > 0) {
          for (const budgetFactItem of budgetFactPlanArrForCheckingShr) {
            const shr = this.shrSpfList.find(el => parseInt(el) === parseInt(budgetFactItem.spf));
            if (shr) {
              const shrMainList = await this.getShRList();
              const checkNewShrData = this.checkNewShrData(shrMainList, this.budgetFactPlanArrForCheckingShr);
              if (checkNewShrData) {
                this.showInTop = true;
              } else {
                this.showInTop = false;
              }
            }
          }
        }
      },

      setShrSpfValInList(item){
        if (this.shrSpfList.length>0){
          for (const shrSpf of this.shrSpfList){
            if (parseInt(item.spf) === parseInt(shrSpf)){
              item.shr=true;
            }
          }
        }
        return item;
      },

      generateMainObj(mainObj, year){
          // console.log("mainObj: " + JSON.stringify(mainObj));
        const firstYear = year;
        const secondYear = year+1;
        const thirdYear = year+2;
        let firstYearVal = 0;
        let inflationRate = false;
        let secondYearVal = 0;
        let thirdYearVal = 0;
        let refinedPlan = Math.round(parseFloat(mainObj.budgetFormTotal.planSum) - parseFloat(mainObj.budgetFormTotal.oneTimeExpenses));

        const shr = this.shrSpfList.find(el => parseInt(el) === parseInt(mainObj.budgetFormTotal.spf));
        if (!shr){
          inflationRate = true;
        }

        for (const form of mainObj.budgetSeparateSpecific.inputFormData) {
            if (parseInt(new Date(form.date).getFullYear()) === parseInt(firstYear)) {
                firstYearVal = form.value;
            } else if (parseInt(new Date(form.date).getFullYear()) === parseInt(secondYear)) {
                secondYearVal = form.value;
            } else if (parseInt(new Date(form.date).getFullYear()) === parseInt(thirdYear)) {
                thirdYearVal = form.value;
            }
        }
        if (mainObj.budgetFormTotal.forecastOne === true || mainObj.budgetFormTotal.forecastTwo === true || mainObj.budgetFormTotal.forecastThree === true) {
            refinedPlan = Math.round((parseFloat(mainObj.budgetFormTotal.planSum ? mainObj.budgetFormTotal.planSum : 0) + parseFloat(mainObj.budgetFormTotal.rbTransfert ? mainObj.budgetFormTotal.rbTransfert : 0) + parseFloat(mainObj.budgetFormTotal.correctedPlan ? mainObj.budgetFormTotal.correctedPlan : 0)) - parseFloat(mainObj.budgetFormTotal.oneTimeExpenses ? mainObj.budgetFormTotal.oneTimeExpenses : 0));
        }

        return {
            id: mainObj.budgetFormTotal.id,
            nameRu: mainObj.spfTitle.nameRu,
            nameKk: mainObj.spfTitle.nameKk,
            spf: mainObj.budgetFormTotal.spf,
            factSum: parseFloat(mainObj.budgetFormTotal.factSum)/1000,
            // factSum: parseFloat(mainObj.budgetFormTotal.factSum),
            planSum: mainObj.budgetFormTotal.planSum,
            oneTimeExpenses: this.isValueEmpty(mainObj.budgetFormTotal.oneTimeExpenses) ? Math.round(mainObj.budgetFormTotal.oneTimeExpenses) : 0,
            rbTransfert: this.isValueEmpty(mainObj.budgetFormTotal.rbTransfert) ? Math.round(mainObj.budgetFormTotal.rbTransfert) : 0,
            correctedPlan: this.isValueEmpty(mainObj.budgetFormTotal.correctedPlan) ? Math.round(mainObj.budgetFormTotal.correctedPlan) : 0,
            refinedPlan: refinedPlan,
            forecastYearPlusOne: mainObj.budgetFormTotal.forecastOne===true ? Math.round(mainObj.budgetFormTotal.forecastYearPlusOne) : parseInt("0"),
            forecastYearPlusOneShr: '',
            forecastOne: mainObj.budgetFormTotal.forecastOne,
            percentOfGrowth: mainObj.budgetFormTotal.percentOfGrowth,
            twoYearPlusForecast: mainObj.budgetFormTotal.forecastTwo===true ? Math.round(mainObj.budgetFormTotal.twoYearPlusForecast) : parseInt("0"),
            twoYearPlusForecastShr: '',
            forecastTwo: mainObj.budgetFormTotal.forecastTwo,
            expensesInExcessOfParameters: Math.round(mainObj.budgetFormTotal.expensesInExcessOfParameters),
            threeYearPlusForecast: mainObj.budgetFormTotal.forecastThree===true ? Math.round(mainObj.budgetFormTotal.threeYearPlusForecast) : parseInt("0"),
            threeYearPlusForecastShr: '',
            forecastThree: mainObj.budgetFormTotal.forecastThree,
            year: mainObj.budgetFormTotal.year,
            region: mainObj.budgetFormTotal.region,
            abp: mainObj.budgetFormTotal.abp,
            prg: mainObj.budgetFormTotal.prg,
            ppr: mainObj.budgetFormTotal.ppr,
            ga: mainObj.budgetFormTotal.ga,
            userId: mainObj.budgetFormTotal.userId,
            firstYearVal: firstYearVal,
            secondYearVal: secondYearVal,
            thirdYearVal: thirdYearVal,
            inflationRate: inflationRate,
            checkRow: false,
            shrValue: null,
            shr: false,
            shrOneValue: mainObj.budgetFormTotal.forecastOne===true ? Math.round(mainObj.budgetFormTotal.forecastYearPlusOne) : parseInt("0"),
            shrTwoValue: mainObj.budgetFormTotal.forecastTwo===true ? Math.round(mainObj.budgetFormTotal.twoYearPlusForecast) : parseInt("0"),
            shrThreeValue: mainObj.budgetFormTotal.forecastThree===true ? Math.round(mainObj.budgetFormTotal.threeYearPlusForecast) : parseInt("0"),
            forecastYearPlusOneInit: mainObj.budgetFormTotal.forecastOne===true ? Math.round(mainObj.budgetFormTotal.forecastYearPlusOne) : parseInt("0"),
            twoYearPlusForecastInit: mainObj.budgetFormTotal.forecastTwo===true ? Math.round(mainObj.budgetFormTotal.twoYearPlusForecast) : parseInt("0"),
            threeYearPlusForecastInit: mainObj.budgetFormTotal.forecastThree===true ? Math.round(mainObj.budgetFormTotal.threeYearPlusForecast) : parseInt("0"),
            db: mainObj.budgetFormTotal.db
        }
      },

      generateNewObj(spfItem){
        const factSum = parseFloat(spfItem.fact552)/1000;
        //   const factSum = parseFloat(spfItem.fact552);
        const planSum = parseFloat(spfItem.planFin ? spfItem.planFin : 0)
        const rbTransfert = parseFloat(spfItem.rbTransfert ? spfItem.rbTransfert : 0);
        const correctedPlan = parseFloat(spfItem.correctedPlan ? spfItem.correctedPlan : 0);
        const oneTimeExpenses = parseFloat(spfItem.oneTimeExpenses ? spfItem.oneTimeExpenses : 0)
        let refinedPlan = 0;
        if (this.isValNotEmptyAndNotNullAndNotZero(spfItem.fact552) || this.isValNotEmptyAndNotNullAndNotZero(spfItem.planFin)) {
          refinedPlan = Math.round((planSum + rbTransfert + correctedPlan) - oneTimeExpenses);
        } else {
          refinedPlan = parseInt(0);
        }
        return {
            id: null,
            nameRu: spfItem.nameRu,
            nameKk: spfItem.nameKk,
            spf: spfItem.spf,
            factSum: factSum,
            planSum: planSum,
            oneTimeExpenses: parseInt(0),
            rbTransfert: parseInt(0),
            correctedPlan: parseInt(0),
            refinedPlan: refinedPlan,
            forecastYearPlusOne: parseInt(0),
            forecastYearPlusOneShr: '',
            forecastOne: false,
            percentOfGrowth: parseFloat(0).toFixed(2),
            twoYearPlusForecast: parseInt(0),
            twoYearPlusForecastShr: '',
            forecastTwo: false,
            twoYearPlusPercent: parseFloat(0).toFixed(2),
            expensesInExcessOfParameters: parseInt(0),
            threeYearPlusForecast: parseInt(0),
            threeYearPlusForecastShr: '',
            forecastThree: false,
            threeYearPlusPercent: parseFloat(0).toFixed(2),
            year: this.curYear,
            region: this.currentRegion,
            abp: this.header.abp,
            prg: this.header.prg,
            ppr: this.header.ppr,
            ga: '',
            userId: this.userId,
            firstYearVal: 0,
            secondYearVal: 0,
            thirdYearVal: 0,
            inflationRate: false,
            checkRow: false,
            shrValue: null,
            shr: false,
            shrOneValue: parseInt(0),
            shrTwoValue: parseInt(0),
            shrThreeValue: parseInt(0),
            forecastYearPlusOneInit: parseInt(0),
            twoYearPlusForecastInit: parseInt(0),
            threeYearPlusForecastInit: parseInt(0),
            db: false
        }
      },

      generateNewObjOnlyForCheckingShrInList(spfItem){
        return {
          id: null,
          nameRu: spfItem.nameRu,
          nameKk: '',
          spf: spfItem.spf,
          factSum: parseInt(0),
          planSum: parseInt(0),
          oneTimeExpenses: parseInt(0),
          rbTransfert: parseInt(0),
          correctedPlan: parseInt(0),
          refinedPlan: 0,
          forecastYearPlusOne: parseInt(0),
          forecastYearPlusOneShr: '',
          forecastOne: false,
          percentOfGrowth: parseFloat(0).toFixed(2),
          twoYearPlusForecast: parseInt(0),
          twoYearPlusForecastShr: '',
          forecastTwo: false,
          twoYearPlusPercent: parseFloat(0).toFixed(2),
          expensesInExcessOfParameters: parseInt(0),
          threeYearPlusForecast: parseInt(0),
          threeYearPlusForecastShr: '',
          forecastThree: false,
          threeYearPlusPercent: parseFloat(0).toFixed(2),
          year: this.curYear,
          region: this.currentRegion,
          abp: this.header.abp,
          prg: this.header.prg,
          ppr: this.header.ppr,
          ga: '',
          userId: this.userId,
          firstYearVal: 0,
          secondYearVal: 0,
          thirdYearVal: 0,
          inflationRate: false,
          checkRow: false,
          shrValue: null,
          shr: false,
          shrOneValue: parseInt(0),
          shrTwoValue: parseInt(0),
          shrThreeValue: parseInt(0),
          forecastYearPlusOneInit: parseInt(0),
          twoYearPlusForecastInit: parseInt(0),
          threeYearPlusForecastInit: parseInt(0),
          db: false
        }
      },

      //--Получить либо сгенерировать список для формы spf: 144
      async getFuelTypesServices(show, budgetItem) {
        // console.log('budgetItemFuelTypesServices: ' + JSON.stringify(budgetItem));
        let arrFromDB = await this.getListOfCommServicesFromDB(budgetItem.spf); //---Ранее сохраненные комм.услуги формы 144
        // console.log('arrFromDB: ' + JSON.stringify(arrFromDB));
        let initForm = await this.getListOfTypeOfFuels(budgetItem);
        // console.log('initForm: ' + JSON.stringify(initForm));
        initForm = this.jointMainArrWithSpecificValuesForEachYear(initForm, this.modalSeparateSpecifics144);
        const fuelTypesArr = this.countValues(initForm);
        arrFromDB = this.getArrWithObjectsFromDB(arrFromDB, initForm);
        if (arrFromDB.length>0){
            this.form144 = arrFromDB;
        } else {
            this.form144 = fuelTypesArr;
        }
        const totalResults = this.countTotalResults(initForm);
        this.spf144ObjWithTotalResults = this.getObjectWithTotalResult(budgetItem, totalResults);
        this.modalFuelServicesVisible = show;
      },

      async getListOfTypeOfFuels(budgetItem) {
        const param = {
          region: this.currentRegion,
          abp: this.header.abp,
          prg: this.header.prg,
          ppr: this.header.ppr,
          spf: budgetItem.spf,
          curYear: parseInt(this.header.year) - 1,
          year: parseInt(this.header.year) - 1
        }
        const urlLink = '/api/request-form/144/' + param.region + '/' + param.abp + '/' + param.prg + '/' + this.header.ppr + '/' + param.spf + '/' + param.curYear + '/' + param.year
        const response = await fetch(urlLink);
        return await response.json();
      },

      //--Получить либо сгенерировать список комм.услуг с расчётами, которые отображаются в форме spf: 151
      async getUtilityServices(show, budgetItem) {
        let arrFromDB = await this.getListOfCommServicesFromDB(budgetItem.spf); //---Ранее сохраненные комм.услуги формы 151
        let items = await this.getListOfBudgetRequestForms(budgetItem);
        if (items.length>0) {
          items = this.jointMainArrWithSpecificValuesForEachYear(items, this.modalSeparateSpecifics151);
          const commServicesArr = this.countValues(items);
          // console.log('commServicesArr: ' + JSON.stringify(commServicesArr));

          arrFromDB = this.getArrWithObjectsFromDB(arrFromDB, commServicesArr);
          // console.log('arrFromDB: ' + JSON.stringify(arrFromDB));
          if (arrFromDB.length > 0) {
            this.commServicesArr = arrFromDB;
          } else {
            this.commServicesArr = commServicesArr;
          }
          const totalResults = this.countTotalResults(items);
          this.spf151ObjWithTotalResults = this.getObjectWithTotalResult(budgetItem, totalResults);
        }
        this.modalUtilityServicesVisible = show;
      },
      //--Привести полученный массив с объектами в общий вид для модального окна spf:151 и 144
      getArrWithObjectsFromDB(arrFromDB, commServicesArr){
        const arr = [];
        if (arrFromDB.length > 0 && commServicesArr.length > 0){
            for (const initFromDB of arrFromDB){
                for (const com of commServicesArr) {
                    if (initFromDB.commService === com.code && initFromDB.year === com.firstYear) {
                        const obj = {
                            index: com.index,
                            nameRu: com.nameRu,
                            nameKk: com.nameKk,
                            nameEn: com.nameEn,
                            code: com.code,
                            value: com.value,
                            indicatorDictFirstItemId: com.indicatorDictFirstItemId,
                            firstYear: com.firstYear,
                            valOne: com.valOne,
                            secondYear: com.secondYear,
                            secondVal: com.secondVal,
                            thirdYear: com.thirdYear,
                            thirdVal: com.thirdVal,
                            countFirstVal: initFromDB.forecastForYear,
                            countSecondVal: initFromDB.forecastForTwoYears,
                            countThirdVal: initFromDB.forecastForThreeYears
                        }
                        arr.push(obj);
                    }
                }
            }
        }
        return arr;
      },

      //---Получаем объект с финальными показателями spf: 151 либо 144 для записи "budget_form_limits"
      getObjectWithTotalResult(budgetItem, totalResults){
        // ---------Вычисление в столбце "% роста - 2024"
        const percentOfGrowth = !isNaN(totalResults.totalForecastOne) && parseFloat(budgetItem.refinedPlan)!==parseFloat('0') ? parseFloat((parseFloat(totalResults.totalForecastOne) / parseFloat(budgetItem.refinedPlan)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
        // ---------Вычисление в столбце "% роста - 2025 год"
        const twoYearPlusPercent = !isNaN(totalResults.totalForecastTwo) && parseFloat(totalResults.totalForecastOne)!==parseFloat('0') ? parseFloat((parseFloat(totalResults.totalForecastTwo) / parseFloat(totalResults.totalForecastOne)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
        // // ---------Вычисление в столбце "% роста - 2026 год"
        const threeYearPlusPercent = !isNaN(totalResults.totalForecastThree) && parseFloat(totalResults.totalForecastTwo)!==parseFloat('0') ? parseFloat((parseFloat(totalResults.totalForecastThree) / parseFloat(totalResults.totalForecastTwo)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
        return {
          "spf": budgetItem.spf,
          "title": budgetItem.title,
          "factSum": parseFloat(budgetItem.factSum).toFixed(2),
          "planSum": parseFloat(budgetItem.planSum).toFixed(2),
          "oneTimeExpenses": parseInt(budgetItem.oneTimeExpenses),
          "rbTransfert": parseInt(budgetItem.rbTransfert),
          "correctedPlan": parseInt(budgetItem.correctedPlan),
          "refinedPlan": parseInt(budgetItem.refinedPlan),
          "forecastYearPlusOne": parseInt(totalResults.totalForecastOne),
          "forecastOne": true,
          "expensesInExcessOfParameters": parseInt(budgetItem.expensesInExcessOfParameters),
          "percentOfGrowth": parseFloat(percentOfGrowth).toFixed(2), //-----
          "twoYearPlusForecast": parseInt(totalResults.totalForecastTwo),
          "forecastTwo": true,
          "twoYearPlusPercent": parseFloat(twoYearPlusPercent).toFixed(2), //-----
          "threeYearPlusForecast": parseInt(totalResults.totalForecastThree),
          "forecastThree": true,
          "threeYearPlusPercent": parseFloat(threeYearPlusPercent).toFixed(2), //-----
          "year": parseInt(budgetItem.year),
          "region": budgetItem.region,
          "abp": budgetItem.abp,
          "prg": budgetItem.prg,
          "ppr": budgetItem.ppr,
          "ga": budgetItem.ga,
          "userId": budgetItem.userId
        }
      },

      countValues(items){
          if (items.length > 0){
              for (const init of items){
                  const countFirstVal = parseFloat(init.value) + (parseFloat(init.value) * parseFloat(init.valOne))/parseInt(100);
                  const countSecondVal = parseFloat(countFirstVal) + (parseFloat(countFirstVal) * parseFloat(init.secondVal))/parseInt(100);
                  const countThirdVal = parseFloat(countSecondVal) + (parseFloat(countSecondVal) * parseFloat(init.thirdVal))/parseInt(100);
                  init.countFirstVal = Math.round(countFirstVal);
                  init.countSecondVal = Math.round(countSecondVal);
                  init.countThirdVal = Math.round(countThirdVal);
              }
          }
          return items;
      },

      countTotalResults(initArr){
        const totalObj = {}
        if (initArr.length > 0){
          totalObj.totalCount = 0;
          totalObj.totalForecastOne = 0;
          totalObj.totalForecastTwo = 0;
          totalObj.totalForecastThree = 0;
          for (const init of initArr){
            totalObj.totalCount += parseFloat(init.value);
            totalObj.totalForecastOne += parseInt(init.countFirstVal);
            totalObj.totalForecastTwo += parseInt(init.countSecondVal);
            totalObj.totalForecastThree += parseInt(init.countThirdVal);
          }
        }
        return totalObj;
      },

      jointMainArrWithSpecificValuesForEachYear(items, modalSeparateSpecifics){
        if (items.length>0 && modalSeparateSpecifics.length>0){
          for (const item of items){
            for (const modal of modalSeparateSpecifics){
              if (parseInt(item.id) === parseInt(modal.indicatorDictFirstItemId)){
                item.valOne = modal.valOne;
                item.secondVal = modal.valTwo;
                item.thirdVal = modal.valThree;
              }
            }
          }
        }
        return items;
      },
      //--Получаем сервис ранее сохраненных данных в БД для формы spf:151
      async getListOfCommServicesFromDB(spf) {
        const param = {
          abp: this.header.abp,
          prg: this.header.prg,
          ppr: this.header.ppr,
          spf: spf,
          year: this.header.year,
          region: this.currentRegion,
          userId: this.userId
        }
        const urlLink = '/api/form-limits-commservice/new/list/' + param.abp + '/' + param.prg + '/' + this.header.ppr + '/' + param.spf + '/' + param.year + '/'+ param.region
        const response = await fetch(urlLink);
        const spf151List = await response.json();
        return spf151List;
      },
      //--Получаем сервис для формы spf:151
      async getListOfBudgetRequestForms(budgetItem){
        const param = {
          region: this.currentRegion,
          abp: this.header.abp,
          prg: this.header.prg,
          ppr: this.header.ppr,
          spf: budgetItem.spf,
          curYear: parseInt(this.header.year)-1,
          year: parseInt(this.header.year)-1
        }
        const urlLink = '/api/request-form/new/'+param.region+'/'+param.abp+'/'+param.prg+'/'+this.header.ppr+'/'+param.spf+'/'+param.curYear+'/'+param.year
        const response = await fetch(urlLink);
        const spf151List = await response.json();
        return spf151List;
      },

      //--Перерасчёт при вводе данных вручную в форме 151 и 159 и 144
      changeFirstYearVal(commItem, val){
          val = val.replace(/\s+/g, '');
          const countSecondVal = parseFloat(val) + (parseFloat(val) * parseFloat(commItem.secondVal))/parseInt(100);
          const countThirdVal = parseFloat(countSecondVal) + (parseFloat(countSecondVal) * parseFloat(commItem.thirdVal))/parseInt(100);
          commItem.countFirstVal = parseFloat(val);
          commItem.countSecondVal = !isNaN(countSecondVal) ? parseFloat(countSecondVal) : parseInt('0');
          commItem.countThirdVal = !isNaN(countThirdVal) ? parseFloat(countThirdVal) : parseInt('0');
      },
      changeSecondYearVal(commItem, val){
        val = val.replace(/\s+/g, '');
        const countThirdVal = parseFloat(val) + (parseFloat(val) * parseFloat(commItem.thirdVal))/parseInt(100);
        commItem.countSecondVal = parseFloat(val.replace(/\s+/g, ''));
        commItem.countThirdVal = !isNaN(countThirdVal) ? countThirdVal : parseInt('0');
      },
      changeThirdYearVal(commItem, val){
        val = val.replace(/\s+/g, '');
        commItem.countThirdVal = parseFloat(val.replace(/\s+/g, ''));
      },

      //--Получить либо сгенерировать список услуг по выбросу мусора с расчётами, которые отображаются в форме spf: 159
      async getOtherServices(show, budgetItem) {
          // console.log('budgetItem: ' + JSON.stringify(budgetItem));
          const mainObjArr = await this.getSpecificDataForSpf159(this.header, budgetItem.refinedPlan, budgetItem);
          // console.log('mainObjArr: ' + JSON.stringify(mainObjArr));
          const arrFromDB = await this.getListOfCommServicesFromDB(this.spfSpecificModal.modalSpecifics[2].spf); //---Ранее сохраненные ком.услуги формы 159
          // console.log('arrFromDB: ' + JSON.stringify(arrFromDB));
          if (arrFromDB.length > 0){
              this.form159 = getGarbageDataFromDB(arrFromDB, mainObjArr, budgetItem);   //--Формирую объект из БД для отображения
              // console.log('FROM_DB_this.form159: ' + JSON.stringify(this.form159));
          } else {
              this.form159 = mainObjArr;
              // console.log('Generated_this.form159: ' + JSON.stringify(this.form159));
          }
          this.form159 = this.form159.sort((a, b) => a.index - b.index);
          // console.log('this.form159: ' + JSON.stringify(this.form159));
          if (show) {
              this.modalOtherServicesVisible = show;
          }

          function getGarbageDataFromDB(arrFromDB, mainObjArr, budgetItem){
              const arr = [];
              if (arrFromDB.length > 0 && mainObjArr.length > 0){
                  for (const initFromDB of arrFromDB){
                      for (const mainObj of mainObjArr){
                          if (initFromDB.commService === mainObj.commService && initFromDB.year === mainObj.firstYear){
                              const obj = {
                                  index: mainObj.index,
                                  nameRu: mainObj.nameRu,
                                  nameKk: mainObj.nameKk,
                                  nameEn: mainObj.nameEn,
                                  inputFormId: mainObj.inputFormId,
                                  value: mainObj.value,
                                  spf: mainObj.spf,
                                  commService: mainObj.commService,
                                  initObj: budgetItem,
                                  firstYear: mainObj.firstYear,
                                  firstVal: mainObj.firstVal,
                                  secondYear: mainObj.secondYear,
                                  secondVal: mainObj.secondVal,
                                  thirdYear: mainObj.thirdYear,
                                  thirdVal: mainObj.thirdVal,
                                  countFirstVal: getFirstForecast(initFromDB, budgetItem),
                                  countSecondVal: getSecondForecast(initFromDB, budgetItem),
                                  countThirdVal: getThirdForecast(initFromDB, budgetItem)
                              }
                              arr.push(obj);
                          }
                      }
                  }
              }
              return arr;
          }

          function getFirstForecast(initFromDB, budgetItem){
              if (initFromDB.commService==="" && parseInt(initFromDB.forecastForYear)!==parseInt(budgetItem.forecastYearPlusOne)) {
                  return budgetItem.forecastYearPlusOne;
              }
              return initFromDB.forecastForYear;
          }
          function getSecondForecast(initFromDB, budgetItem){
              if (initFromDB.commService==="" && parseInt(initFromDB.forecastForTwoYears) !== parseInt(budgetItem.twoYearPlusForecast)) {
                  return budgetItem.twoYearPlusForecast;
              }
              return initFromDB.forecastForTwoYears;
          }
          function getThirdForecast(initFromDB, budgetItem){
              if (initFromDB.commService==="" && parseInt(initFromDB.forecastForThreeYears) !== parseInt(budgetItem.threeYearPlusForecast)) {
                  return budgetItem.threeYearPlusForecast;
              }
              return initFromDB.forecastForThreeYears;
          }
      },

      //---Извлекаем данные из сервиса по 159 форме, а также структурируем согласно показателям каждого года
      async getSpecificDataForSpf159(header, refinedPlan, budgetItem) {
            // console.log('budgetItem159: ' + JSON.stringify(budgetItem));
            const region = this.getRegionForKato(this.header.regDetails);
            const curRegion = this.currentRegion;
            // console.log('region: ' + region + ' curRegion: ' + curRegion);
            const year = parseInt(header.year);
            const abp = header.abp;
            const prg = header.prg;
            const ppr = header.ppr;
            const spf = this.spfSpecificModal.modalSpecifics[2].spf;
            const response = await fetch('/api/new/specifics/spf159/' + '/' +year+'/'+abp+'/'+prg+'/'+ppr+'/'+spf+'/'+curRegion+'/'+region+'/'+refinedPlan);
            const itemsArr = await response.json();
            // console.log('itemsArr: ' + JSON.stringify(itemsArr));
            const structuredObjArr = buildArrWithObjForThreeYears(itemsArr, this.header.year, budgetItem);
            let count = countValues(structuredObjArr);
            count = specifyDataToGarbagePart(count, this.modalSeparateSpecifics159);

            return count;

            function buildArrWithObjForThreeYears(dataFromDB, chosenYear, budgetItem) {
                const objArr = [];
                if (dataFromDB.length > 0) {
                    for (const data of dataFromDB) {
                        const obj = {
                            index: data.index,
                            nameRu: data.nameRu,
                            nameKk: data.nameKk,
                            nameEn: data.nameEn,
                            inputFormId: data.inputFormId,
                            value: data.value,
                            spf: budgetItem.spf,
                            commService: data.commService,
                            initObj: budgetItem,
                            firstYear: year,
                            firstVal: 0,
                            secondYear: year+1,
                            secondVal: '',
                            thirdYear: year+2,
                            thirdVal: 0,
                            countFirstVal: 0,
                            countSecondVal: 0,
                            countThirdVal: 0
                        }
                        for (let year = parseInt(chosenYear); year <= parseInt(chosenYear) + 2; year++) {
                            if (year === parseInt(chosenYear)){
                                obj.firstVal = getListOfGarbageService(year, data.inputFormId, data.inputForm);
                            } else if (year === parseInt(chosenYear)+1){
                                obj.secondVal = getListOfGarbageService(year, data.inputFormId, data.inputForm);
                            } else if (year === parseInt(chosenYear)+2){
                                obj.thirdVal = getListOfGarbageService(year, data.inputFormId, data.inputForm);
                            }
                        }
                        objArr.push(obj);
                    }
                }
                return objArr;
            }
            function getListOfGarbageService(year, inputFormId, inputForm){
                if (inputForm.length > 0) {
                    for (const input of inputForm) {
                        if (parseInt(new Date(input.date).getFullYear()) === parseInt(year) && (parseInt(input.inputFormIndicatorId) === parseInt(inputFormId))) {
                            return parseFloat(input.value).toFixed(2);
                        }
                    }
                }
                return parseFloat('0').toFixed(2);
            }   //---Извлекает значение согласно году
            function countValues(initArr){
                if (initArr.length > 0){
                    for (const init of initArr){
                        const countFirstVal = parseFloat(init.value) + (parseFloat(init.value) * parseFloat(init.firstVal))/parseInt(100);
                        const countSecondVal = parseFloat(countFirstVal) + (parseFloat(countFirstVal) * parseFloat(init.secondVal))/parseInt(100);
                        const countThirdVal = parseFloat(countSecondVal) + (parseFloat(countSecondVal) * parseFloat(init.thirdVal))/parseInt(100);
                        init.countFirstVal = parseFloat(countFirstVal).toFixed(2);
                        init.countSecondVal = parseFloat(countSecondVal).toFixed(2);
                        init.countThirdVal = parseFloat(countThirdVal).toFixed(2);
                    }
                }
                return initArr;
            }   //--Расчёт данных из БД по формуле
            function specifyDataToGarbagePart(mainObjArr, modalSeparateSpecifics159){
              if (mainObjArr.length>0 && modalSeparateSpecifics159.length>0){
                for (const main of mainObjArr){
                  for (const modal of modalSeparateSpecifics159){
                    if (main.commService && parseInt(main.commService) === parseInt(modal.indicatorDictFirstItemId)){
                      main.initObj = modal;
                      main.firstYear = modal.firstYear;
                      main.firstVal = modal.valOne;
                      main.secondYear = modal.secondYear;
                      main.secondVal = modal.valTwo;
                      main.thirdYear = modal.thirdYear;
                      main.thirdVal = modal.valThree;
                    }
                  }
                }
              }
              return mainObjArr;
            }
      },

      getRegionForKato(regionStr) {
        if (regionStr !== '' && regionStr.endsWith('01')) {
          const oblastEnd = '0101';
          const regionEnd = '01';
          const sizeOfWord = regionStr.length;
          const getStartIndx = (endOfRegion) => parseInt(sizeOfWord) - endOfRegion.length;
          const getHeadOfWord = (word, start, end) => word.substring(start, end);
          let regionChanged = '';

          if (regionStr.endsWith(oblastEnd)){
            const headOfWord = getHeadOfWord(regionStr, 0, getStartIndx(oblastEnd));
            regionChanged = headOfWord + '0000';
          } else if (regionStr.endsWith(regionEnd)) {
            const headOfWord = getHeadOfWord(regionStr, 0, getStartIndx(regionEnd));
            regionChanged = headOfWord + '00';
          }
          return regionChanged;
        } else if (!regionStr.endsWith('01')){
          const sizeOfWord = regionStr.length;
          const getHeadOfWord = (word, start, end) => word.substring(start, end);
          const headOfWord = getHeadOfWord(regionStr, 0, (sizeOfWord-2));
          return headOfWord + '00';
        } else {
          return regionStr;
        }
      },

      //--Сохранение типа топлива из формы 144 в БД
      saveFuelTypesServices(acceptance){
        this.modalFuelServicesVisible = acceptance;
          if (acceptance){
            this.limitFormFuelTypesArr = [];
            // console.log('this.form144: ' + JSON.stringify(this.form144));
            this.limitFormFuelTypesArr = this.getCommServiceArr(this.form144, this.header, this.spfSpecificModal.modalSpecifics[0].spf, this.userId, this.currentRegion);
            // console.log('this.limitFormFuelTypesArr: ' + JSON.stringify(this.limitFormFuelTypesArr));
              this.setTotalResults144();
            this.saveDataToDB('fuel_types');
          }
      },

        setTotalResults144(){
          if (this.budgetFactPlanArr.length > 0){
              for (const item of this.budgetFactPlanArr){
                  if (parseInt(item.spf) === this.spfSpecificModal.modalSpecifics[0].spf){
                      item.forecastYearPlusOne = this.totalFirstCountSpf144;
                      item.twoYearPlusForecast = this.totalSecondCountSpf144;
                      item.threeYearPlusForecast = this.totalThirdCountSpf144;
                  }
              }
          }
        },

      //--Сохранение комм.услуг из формы 151 в БД
      saveUtilityServices(acceptance){
          this.modalUtilityServicesVisible = acceptance;
          if (acceptance){
              this.limitsFormCommServicesArr = [];
              // console.log('commServicesArr: ' + JSON.stringify(this.commServicesArr));
              this.limitsFormCommServicesArr = this.getCommServiceArr(this.commServicesArr, this.header, this.spfSpecificModal.modalSpecifics[1].spf, this.userId, this.currentRegion);
              // console.log('this.limitsFormCommServicesArr: ' + JSON.stringify(this.limitsFormCommServicesArr));
              this.setTotalResults151();
              this.saveDataToDB('comm_service');
          }
      },

        setTotalResults151(){
          if (this.budgetFactPlanArr.length > 0){
              for (const item of this.budgetFactPlanArr){
                  if (parseInt(item.spf) === this.spfSpecificModal.modalSpecifics[1].spf){
                      item.forecastYearPlusOne = this.totalFirstCount;
                      item.twoYearPlusForecast = this.totalSecondCount;
                      item.threeYearPlusForecast = this.totalThirdCount;
                  }
              }
          }
        },

      //--Создать объект для spf: 151 и 144
      getCommServiceArr(commServicesArr, header, spf, userId, currentRegion){
        const arr = [];
        if (commServicesArr.length > 0) {
          for (const comService of commServicesArr) {
            const commServicesObj = {
              abp: parseInt(header.abp),
              prg: parseInt(header.prg),
              ppr: parseInt(header.ppr),
              spf: parseInt(spf),
              commService: comService.code,
              year: parseInt(header.year),
              forecastForYear: parseFloat(comService.countFirstVal),
              forecastForTwoYears: parseFloat(comService.countSecondVal),
              forecastForThreeYears: parseFloat(comService.countThirdVal),
              region: currentRegion,
              userName: userId
            }
            arr.push(commServicesObj);
          }
        }
        return arr;
      },

      //--Сохранение услуг вывоза мусора из формы 159 в БД
      saveOtherServices(acceptance){
        this.modalOtherServicesVisible = acceptance;
        if (!this.isWarning && acceptance && this.form159.length > 0){
          this.spf159ObjWithTotalResults = getObjectWithTotalResult(this.form159);
          // console.log('this.spf159ObjWithTotalResults: ' + JSON.stringify(this.spf159ObjWithTotalResults));
          this.limitFormGarbageServicesArr = getObjectFormLimitsGarbageServices(this.form159, this.userId);
          this.setTotalResults159(this.limitFormGarbageServicesArr);
          this.saveDataToDB('garbage_service');
        }
        //---Получаем объект с финальными показателями spf:159 для записи "budget_form_limits"
        function getObjectWithTotalResult(form159) {
          const firstIndxObj = form159[0];
          // console.log('firstIndxObj: ' + JSON.stringify(firstIndxObj));
          // ---------Вычисление в столбце "% роста - 2024"
          const percentOfGrowth = !isNaN(firstIndxObj.countFirstVal) && parseFloat(firstIndxObj.initObj.refinedPlan)!==parseFloat('0') ? parseFloat((parseFloat(firstIndxObj.countFirstVal) / parseFloat(firstIndxObj.initObj.refinedPlan)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
          // ---------Вычисление в столбце "% роста - 2025 год"
          const twoYearPlusPercent = !isNaN(firstIndxObj.countSecondVal) && parseFloat(firstIndxObj.countFirstVal)!==parseFloat('0') ? parseFloat((parseFloat(firstIndxObj.countSecondVal) / parseFloat(firstIndxObj.countFirstVal)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
          // // ---------Вычисление в столбце "% роста - 2026 год"
          const threeYearPlusPercent = !isNaN(firstIndxObj.countThirdVal) && parseFloat(firstIndxObj.countSecondVal)!==parseFloat('0') ? parseFloat((parseFloat(firstIndxObj.countThirdVal) / parseFloat(firstIndxObj.countSecondVal)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
          return {
            "spf": firstIndxObj.initObj.spf,
            "title": firstIndxObj.initObj.title,
            "factSum": parseFloat(firstIndxObj.initObj.factSum).toFixed(2),
            "planSum": parseFloat(firstIndxObj.initObj.planSum).toFixed(2),
            "oneTimeExpenses": parseInt(firstIndxObj.initObj.oneTimeExpenses),
            "rbTransfert": parseInt(firstIndxObj.initObj.rbTransfert),
            "correctedPlan": parseInt(firstIndxObj.initObj.correctedPlan),
            "refinedPlan": parseInt(firstIndxObj.initObj.refinedPlan).toFixed(2),
            "forecastYearPlusOne": Math.round(firstIndxObj.countFirstVal),
            "forecastOne": true,
            "expensesInExcessOfParameters": parseInt(firstIndxObj.initObj.expensesInExcessOfParameters),
            "percentOfGrowth": parseFloat(percentOfGrowth).toFixed(2), //-----
            "twoYearPlusForecast": Math.round(firstIndxObj.countSecondVal),
            "forecastTwo": true,
            "twoYearPlusPercent": parseFloat(twoYearPlusPercent).toFixed(2), //-----
            "threeYearPlusForecast": Math.round(firstIndxObj.countThirdVal),
            "forecastThree": true,
            "threeYearPlusPercent": parseFloat(threeYearPlusPercent).toFixed(2), //-----
            "year": parseInt(firstIndxObj.initObj.year),
            "region": firstIndxObj.initObj.region,
            "abp": firstIndxObj.initObj.abp,
            "prg": firstIndxObj.initObj.prg,
            "ppr": firstIndxObj.initObj.ppr,
            "ga": firstIndxObj.initObj.ga,
            "userId": firstIndxObj.initObj.userId
          }
        }
        function getObjectFormLimitsGarbageServices(form159, userId){
          const arr = [];
          if (form159.length > 0){
            for (const obj of form159){
              const commServicesObj = {
                abp: parseInt(obj.initObj.abp),
                prg: parseInt(obj.initObj.prg),
                ppr: parseInt(obj.initObj.ppr),
                spf: parseInt(obj.initObj.spf),
                commService: obj.commService,
                year: parseInt(obj.initObj.year),
                forecastForYear: Math.round(obj.countFirstVal),
                forecastForTwoYears: Math.round(obj.countSecondVal),
                forecastForThreeYears: Math.round(obj.countThirdVal),
                region: obj.initObj.region,
                userName: userId
              }
              arr.push(commServicesObj);
            }
          }
          return arr;
        }
      },

        setTotalResults159(limitFormGarbageServicesArr) {
            const obj = getFirstRow(limitFormGarbageServicesArr);
            if (this.budgetFactPlanArr.length > 0) {
                for (const item of this.budgetFactPlanArr) {
                    if (parseInt(item.spf) === this.spfSpecificModal.modalSpecifics[2].spf){
                        item.forecastYearPlusOne = Object.keys(obj).length!==0 ? obj.forecastForYear : 0;
                        item.twoYearPlusForecast = Object.keys(obj).length!==0 ? obj.forecastForTwoYears : 0;
                        item.threeYearPlusForecast = Object.keys(obj).length!==0 ? obj.forecastForThreeYears : 0;
                    }
                }
            }

            function getFirstRow(arr){
                if (arr.length > 0) {
                    for (const item of arr){
                        if (item.commService===""){
                            return item;
                        }
                    }
                }
                return {};
            }
        },
      // -------Метод для отображения диалогового окна для сохранения данных в БД----//
      saveDataToDB(param) {
        this.$bvModal.msgBoxConfirm(
          'Вы действительно хотите сохранить данные?',
          {
            title: 'Подтверждение',
            size: 'lg',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Да',
            cancelTitle: 'Нет',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(async value => {
            if (value) {
              const listOfValuesForSave = this.compareAndFindValuesNotNull();
              if (param === 'all') { //--Сохранение в БД по основной гриде
                if (listOfValuesForSave.length > 0 && listOfValuesForSave.length===this.budgetFactPlanArr.length) {
                  await this.saveLimitDataToDB(listOfValuesForSave, false);
                } else {
                    this.makeToastWarn('warning', 'Ошибка сохранения', 'Не все поля заполнены');
                }
              }
              else if (param === 'fuel_types') { //--Сохранение в БД по форме 144
                await this.fuel_types(listOfValuesForSave);
              }
              else if (param === 'comm_service') { //--Сохранение в БД по форме 151
                await this.comm_service(listOfValuesForSave);
              }
              else if (param === 'garbage_service'){ //--Сохранение в БД по форме 159
                await this.garbage_service(listOfValuesForSave);
              }
            }
          })
          .catch(error => {
            this.makeToast('danger', 'Ошибка сохранения', error.toString());
            this.loading = false;
          });
      },

      async fuel_types(listOfValuesForSave) {
        const arr = [];
        if (JSON.stringify(this.spf144ObjWithTotalResults) !== '{}') {
          const spf144ObjWithTotalResults = this.spf144ObjWithTotalResults;
          spf144ObjWithTotalResults.forecastYearPlusOne = this.totalFirstCountSpf144;
          spf144ObjWithTotalResults.twoYearPlusForecast = this.totalSecondCountSpf144;
          spf144ObjWithTotalResults.threeYearPlusForecast = this.totalThirdCountSpf144;
          arr.push(spf144ObjWithTotalResults);
          // console.log('spf144ObjWithTotalResults: ' + JSON.stringify(arr));
          if (this.limitFormFuelTypesArr.length > 0 && listOfValuesForSave.length > 0) {
            const budgetFormLimitData = this.getDataForBudgetFormLimit(listOfValuesForSave, true);  //---New spf:144
            const fuelTypesArr = this.getDataForBudgetFormLimit(arr, false);  //---New
            //--- Сохранить данные модального окна по spf:144 в budget_form_limits_comservices,
            // а также отдельно сохранить spf:144 в budget_form_limits. Затем сохранить весь список основной гриды в budget_form_limits
            await this.saveComServiceAndLimitDataToDB(this.limitFormFuelTypesArr, budgetFormLimitData, fuelTypesArr);
          }
        }
      },  //-- Сохранить данные по spf-144 в БД

      async comm_service(listOfValuesForSave) {
        const arr = [];
        if (JSON.stringify(this.spf151ObjWithTotalResults) !== '{}') {
          const spf151ObjWithTotalResults = this.spf151ObjWithTotalResults;
          spf151ObjWithTotalResults.forecastYearPlusOne = this.totalFirstCount;
          spf151ObjWithTotalResults.twoYearPlusForecast = this.totalSecondCount;
          spf151ObjWithTotalResults.threeYearPlusForecast = this.totalThirdCount;
          arr.push(spf151ObjWithTotalResults);
          if (this.limitsFormCommServicesArr.length > 0 && listOfValuesForSave.length > 0) {  //---New
            const budgetFormLimitData = this.getDataForBudgetFormLimit(listOfValuesForSave, true);  //---New
            const commServiceArr = this.getDataForBudgetFormLimit(arr, false);  //---New
            await this.saveComServiceAndLimitDataToDB(this.limitsFormCommServicesArr, budgetFormLimitData, commServiceArr);  //---New
          }
        }
      },  //-- Сохранить данные по spf-151 в БД

      async garbage_service(listOfValuesForSave) {
        if (JSON.stringify(this.spf159ObjWithTotalResults) !== '{}') {
          const arr = [];
          arr.push(this.spf159ObjWithTotalResults);
          if (this.limitFormGarbageServicesArr.length > 0 && listOfValuesForSave.length > 0) {  //---New
            const budgetFormLimitData = this.getDataForBudgetFormLimit(listOfValuesForSave, true);  //---New
            const commServiceArr = this.getDataForBudgetFormLimit(arr, false);  //---New
            await this.saveComServiceAndLimitDataToDB(this.limitFormGarbageServicesArr, budgetFormLimitData, commServiceArr);  //---New
          }
        }
      },  //-- Сохранить данные по spf-159 в БД

      getDataForBudgetFormLimit(arr, commService){
        return {
          "year": this.header.year,
          "region": this.currentRegion,
          "attribute": this.attributeStatus,
          "commService": commService,
          "budgetFormLimitsEntityDto": arr,
        }
      },  //---Object for budget_form_limits table in DB

      compareAndFindValuesNotNull(){
        const saveDataIntoDB = [];
        const factPlan = this.budgetFactPlanArr;
        for (const row of factPlan) {
          if (
              this.isValNotEmptyAndNotNullAndNotZero(this.isValueNotEmpty(row.factSum)) ||
              this.isValNotEmptyAndNotNullAndNotZero(this.isValueNotEmpty(row.planSum)) ||

              this.isValNotEmptyAndNotNullAndNotZero(this.isValueNotEmpty(row.oneTimeExpenses)) ||
              this.isValNotEmptyAndNotNullAndNotZero(this.isValueNotEmpty(row.rbTransfert)) ||
              this.isValNotEmptyAndNotNullAndNotZero(this.isValueNotEmpty(row.correctedPlan)) ||
              this.isValNotEmptyAndNotNullAndNotZero(this.isValueNotEmpty(row.forecastYearPlusOne)) ||
              this.isValNotEmptyAndNotNullAndNotZero(this.isValueNotEmpty(row.expensesInExcessOfParameters)) ||
              this.isValNotEmptyAndNotNullAndNotZero(this.isValueNotEmpty(row.twoYearPlusForecast)) ||
              this.isValNotEmptyAndNotNullAndNotZero(this.isValueNotEmpty(row.threeYearPlusForecast))
          ) {
                row.forecastOne = true;
                row.forecastTwo = true;
                row.forecastThree = true;
                saveDataIntoDB.push(row);
          }
        }
        return saveDataIntoDB;
      },

      isValueNotEmpty(value) {
        if (!isNaN(value) && isFinite(value) && parseInt(value)!==0){
          return true;
        }
        return false
      },

      isValNotEmptyAndNotNullAndNotZero(value) {
        if (this.isValueEmpty(value) && !isNaN(value) && isFinite(value) && value!==0){
          return true;
        }
        return false
      },

      async getBudgetVariantsAttribute(header){
        const region = this.currentRegion;
        const year = header.year;
        const response = await fetch('/api/budget-variants/attribute/'+year+'/'+region);
        const items = await response.json();
        // console.log('items: ' + JSON.stringify(items));
        if (!items){
            this.makeToast('Warning', 'Первая прогнозная версия бюджета не актуальна. Вы не можете изменять данные');
        }
          // console.log('this.accessLevel: ' + JSON.stringify(this.accessLevel));
        if (this.accessLevel === 2) {
            if (items === true) {
                this.attributeStatus = true;
            } else {
                this.attributeStatus = false;
            }
        } else {
            this.attributeStatus = false;
        }
        return items;
      },

      //----------Сохранение данных в БД по основной гриде
      async saveLimitDataToDB(arr, commService) {
        this.loading = true;
        const arrObj = {
          "year": this.header.year,
          "region": this.currentRegion,
          "attribute": this.attributeStatus,
          "commService": commService,
          "budgetFormLimitsEntityDto": arr,
        }
        // console.log('arrObj: ' + JSON.stringify(arrObj));
        if (arr.length>0){
          try {
            const url = '/api/budget-form-limit/new/add';
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8'
              },
              body: JSON.stringify(arrObj) // массив будет направлен на сервис
            });
            const result = await response.json();
            // console.log('result: ' + JSON.stringify(result));
            if ((response.status === 200) && (result === true)) {
            //---Извлечение всего списка из таблицы "budget_form_limits" по году---//
              await this.getIndexOfInflationIndicators(this.header);
              this.loading = false;
              this.makeToast('Сообщение', 'Данные сохранены');
            } else {
                this.loading = false;
                this.makeToast('Предупреждение', 'Сохранение было отклонено. Запрет на сохранение!');
            }
          } catch {
            this.loading = false;
            this.makeToast('Предупреждение', 'Ошибка сохранения данных');
          }
        }
      },

      //----------Сохранение данные по "коммунальным услугам" spf-144, spf-151 и spf-159 в БД
      async saveComServiceAndLimitDataToDB(arr, formLimitArr, arrDialogForm) {
        const arrObj = {
          "attribute": this.attributeStatus,
          "budgetFormLimitsComServicesEntityDto": arr,
          "formLimitsHeaderDto": formLimitArr,
          "commService": arrDialogForm
        }
        // console.log('arrObj: ' + JSON.stringify(arrObj));
        if (arr.length>0){
          this.loading = true;
          this.modalFuelServicesVisible = false;
          this.modalUtilityServicesVisible = false;
          this.modalOtherServicesVisible = false;
          try {
            const url = '/api/form-limits-commservice/new/add';
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8'
              },
              body: JSON.stringify(arrObj)
            });
            const result = await response.json();
            if (result===true) {
            //---Извлечение всего списка из таблицы "budget_form_limits" по году---//
              // await this.getListOfFormLimit(this.header.year);
              await this.getIndexOfInflationIndicators(this.header);
              this.loading = false;
              this.makeToast('Сообщение', 'Данные сохранены');
            } else {
              this.loading = false;
              this.makeToast('Предупреждение', 'Сохранение невозможно: Версия бюджета неактуальна!');
            }
          } catch {
            this.loading = false;
            this.makeToast('Предупреждение', 'Ошибка сохранения данных');
          }
        }
      },

      removeSpfFromGrid(status, budgetItem){
          if (status){
              budgetItem.checkRow = true;
              this.deleteSingleRow(budgetItem);
          }
      },

      //----------Кнопка для удаления--
      deleteDataToDB(){
        const arrForRemove = [];
          if (this.budgetFactPlanArr.length>0){
            for (const factPlan of this.budgetFactPlanArr){
              if (factPlan.checkRow){
                arrForRemove.push(factPlan);
              }
            }
          }
          if (arrForRemove.length>0){
            const check = this.isEqual(this.budgetFactPlanArrCloneInitVersion, this.budgetFactPlanArr);
            if (!check){
              this.requestForRemoval(arrForRemove);
            }
          }
      },

        //----------Кнопка для удаления одной записи--
        deleteSingleRow(budgetItem){
            const arrForRemove = [];
            arrForRemove.push(budgetItem);
            this.requestForRemoval(arrForRemove);
        },

      isEqual(array1, array2) {
        return JSON.stringify(array1) === JSON.stringify(array2);
      },

      //--------Необходимо подтвердить, удаление
      requestForRemoval(arrForRemove){
        this.$bvModal.msgBoxConfirm(
            'Вы действительно хотите удалить данные?',
            {
              title: 'Подтверждение',
              size: 'lg',
              buttonSize: 'sm',
              okVariant: 'success',
              okTitle: 'Да',
              cancelTitle: 'Нет',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            })
            .then(async value => {
                  if (value) {
                    await this.deleteRequestToDB(arrForRemove);
                  } else {
                      this.checkRowButtonSpf=0;
                      this.checkRowButtonSpf=0;
                      this.selectAllRows = false;
                      this.budgetFactPlanArr.forEach(item => {
                          this.$set(item, 'checkRow', false);
                      });
                  }
            })
            .catch(error => {
              this.makeToast('danger', 'Ошибка удаления', error.toString());
              this.loading = false;
            });
      },
      //-----Запуск процесса удаления-----//
      async deleteRequestToDB(arrForRemove) {
          // console.log('arrForRemove: ' + JSON.stringify(arrForRemove));
        this.loading = true;
        if (arrForRemove.length > 0) {
          try {
            const url = '/api/budget-form-limit/remove';
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8'
              },
              body: JSON.stringify(arrForRemove)
            });
            const result = await response.json();
            // console.log('result: ' + JSON.stringify(result));
            if (result===true) {
              await this.getIndexOfInflationIndicators(this.header);
              this.loading = false;
                this.makeToastWarn('success', 'Процесс удаления', 'Удаление успешно завершено');
            } else {
              this.loading = false;
              this.makeToastWarn('warning', 'Предупреждение', 'Удаление отклонено');
            }
          } catch {
            this.loading = false;
            this.makeToast('danger', 'Ошибка удаления', 'Предупреждение');
          }
        } else {
          this.makeToast('danger', 'Не был выбран элемент для удаления', 'Предупреждение');
        }
      },

      autoCount(){
        const spfSpecificModal = this.spfSpecificModal;
        if (this.budgetFactPlanArr.length>0){
            const checkedRows = getCheckedRows(this.budgetFactPlanArr);
            if (checkedRows.length>0){
                for (const row of checkedRows){
                    const shr = this.shrSpfList.find(el => parseInt(el) === parseInt(row.spf));
                    if (!shr) {
                        const rowSpf = this.budgetFactPlanArr.find(item => item.spf === row.spf);
                        setCounting(rowSpf, spfSpecificModal);
                    }
                }
            } else {
                for (const factPlan of this.budgetFactPlanArr) {
                    const shr = this.shrSpfList.find(el => parseInt(el) === parseInt(factPlan.spf));
                    if (!shr) {
                        setCounting(factPlan, spfSpecificModal);
                    }
                }
            }
        }

        function getCheckedRows(mainArr){
            const arr = [];
            if (mainArr.length>0){
                for (const item of mainArr){
                    if (item.checkRow){
                        arr.push(item);
                    }
                }
            }
            return arr;
        }

        function setCounting(factPlan, spfSpecificModal) {
            if (factPlan.spf !== spfSpecificModal.modalSpecifics[0].spf && factPlan.spf !== spfSpecificModal.modalSpecifics[1].spf && factPlan.spf !== spfSpecificModal.modalSpecifics[2].spf) {
                const countOne = parseFloat(factPlan.refinedPlan) + (parseFloat(factPlan.refinedPlan) * parseFloat(factPlan.firstYearVal)) / parseFloat(100);
                factPlan.forecastYearPlusOne = Math.round(countOne)
                factPlan.percentOfGrowth = !isNaN(factPlan.forecastYearPlusOne) && parseFloat(factPlan.refinedPlan) !== parseFloat('0') ? parseFloat((parseFloat(factPlan.forecastYearPlusOne) / parseFloat(factPlan.refinedPlan)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
                const countTwo = parseFloat(factPlan.forecastYearPlusOne) + (parseFloat(factPlan.forecastYearPlusOne) * parseFloat(factPlan.secondYearVal)) / parseFloat(100);
                factPlan.twoYearPlusForecast = Math.round(countTwo);
                factPlan.twoYearPlusPercent = !isNaN(factPlan.twoYearPlusForecast) && parseFloat(factPlan.forecastYearPlusOne) !== parseFloat('0') ? parseFloat((parseFloat(factPlan.twoYearPlusForecast) / parseFloat(factPlan.forecastYearPlusOne)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
                const countThree = parseFloat(factPlan.twoYearPlusForecast) + (parseFloat(factPlan.twoYearPlusForecast) * parseFloat(factPlan.thirdYearVal)) / parseFloat(100);
                factPlan.threeYearPlusForecast = Math.round(countThree);
                factPlan.threeYearPlusPercent = !isNaN(factPlan.threeYearPlusForecast) && parseFloat(factPlan.twoYearPlusForecast) !== parseFloat('0') ? parseFloat((parseFloat(factPlan.threeYearPlusForecast) / parseFloat(factPlan.twoYearPlusForecast)) * parseFloat(100)).toFixed(2) : parseFloat('0').toFixed(2);
            }
        }
      },

      async getUsrId() {
          return await store.getters.user_uuid;
      },

      async getListOfSpfNames(){
          this.spfListInit = [];
          this.spfRemovedFromMainList = [];
          let arrSpf = [];
          try {
              // --------Извлекаются сведения из таблицы "dict_ebk_ek"----//
              const response = await fetch('/api/dict/get_spf_list/'+this.curYear+'/'+this.currentRegion);
              const itemsArr = await response.json();
              if (this.budgetFactPlanArr.length>0) {
                for (const item of itemsArr) {
                  const isSpfIn = isSpfInList(this.budgetFactPlanArr, item.spf)
                  if (!isSpfIn) {
                    item.check = false;
                    arrSpf.push(item);
                    this.spfListInit.push(item);
                  }
                }
              } else {
                for (const item of itemsArr) {
                  item.check = false;
                  arrSpf.push(item);
                  this.spfListInit.push(item);
                }
              }
              this.spfListInit = this.spfListInit.sort((a, b) => a.spf - b.spf);
          } catch (error) {
              this.makeToast('Внимание', 'Ошибка запроса извлечения "специфик"', error.toString());
          }
          return arrSpf = arrSpf.sort((a, b) => a.spf - b.spf);

          function isSpfInList(mainArr, spfItem){
              for (const arr of mainArr){
                  if (parseInt(arr.spf) === parseInt(spfItem)){
                      return true;
                  }
              }
              return false
          }
      },

      async getUserAccess(userId){
          let accessLevel = '';
          const modules = '004.001.003';
          const url = '/api-py/user-modules/'+userId;
          const response = await fetch(url);
          const items = await response.json();
          const access = getAccess(items, modules);
          accessLevel = access.access_level
          function getAccess(items, modules){
              const res = {};
              if (items.length > 0){
                  for (const item of items){
                      if (item.modules === modules){
                          return item;
                      }
                  }
              }
              return res;
          }
          return accessLevel;
      },

      openFilterByRef(refName) {
          this.$refs.budgetHeader.openFilterByRef(refName);
      },

      padLeadingZeros(num, size) {    // -------добавление нулей перед значением в зависимости от размера значения
          let s = String(num);
          while (s.length < size) { s = '0' + s; }
          return s;
      }, // добавляет 0-ли перед num до size-значного размера

      concatValues(num, size) { // -------Объединяет значения в одну переменную
          let s = String(num);
          while (s.length < size) { s += s; }
          return s;
      },

      getRowKey(row, keys) {  // ----------
          const elArr = [];
          let key = '';
          for (const k of keys) {
              key = this.concatValues(row[k], 1);
              elArr.push(key);
          }
          return elArr;
      }, // преобразует значения выбранных полей в код

      keyup13: function (event) {
          event.preventDefault();
          // Isolate the node that we're after
          const currentNode = event.target;
          // find all tab-able elements
          const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
          // Find the current tab index.
          const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
          // select/focus the following element
          const targetIndex = (currentIndex + 1) % allElements.length;
          if (targetIndex < allElements.length) {
              allElements[targetIndex].select();
          }
          const el = allElements[currentIndex].value;
          if (isNaN(el) || !isFinite(el) || el === '' || !this.isValueEmpty(el)) {
              allElements[currentIndex].value = Math.round(0);
          }
      }, // enter работает как tab

      keyPress: function (event, pattern) {
          // const regex = new RegExp('^[0-9]+$');
          // const regex = new RegExp('^-?\\d*\\d{0,9}$');
          // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
          const regex = new RegExp(pattern);
          // console.log('regex: ' + regex);
          const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
          // console.log('key: ' + key);
          if (!regex.test(key)) {
              event.preventDefault();
              return false;
          }
      }, // вводит по заданному паттерну

      keyPressCorrectedPlan: function (event) {
          // const regex = new RegExp('^[0-9]+$');
          const regex = new RegExp('^-?\\d*\\d{0,9}$');
          // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
          // const regex = new RegExp(pattern);
          // console.log('regex: ' + regex);
          const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
          // console.log('key: ' + key);
          if (!regex.test(key)) {
              event.preventDefault();
              return false;
          }
      }, // вводит по заданному паттерну

      inputFixed(item, field, value, digit) {
          if (isNaN(value) || !isFinite(value) || value === '' || !this.isValueEmpty(value)) {
              value = parseFloat(0);
          }
          this.$set(item, field, parseInt(value));
      }, // форматирует введенное значение до digit цифр после запятой

      //---Проверка на пустое значение---
      isValueEmpty(e) {
          switch (e) {
              case "":
              case NaN:
              case null:
              case false:
              case undefined:
                  return false;
              default:
                  return true;
          }
      },
      //----Выполняется поиск по специфике----//
      onSearchSpf(){
          const spfArr = []
          const spfVal = this.spfListVal;
          this.spfListInit = checkSpfList(this.spfListInit, this.spfRemovedFromMainList); //--очистка массива от ранее добавленных элементов в гриду
          if (this.spfListInit.length>0){
              for (const spfItem of this.spfListInit){
                  const newEl = Object.assign({}, spfItem);   //--создается копия массива
                  if (spfVal){    //--если были введены символы, то выполняется поиск по символам
                      const txt_ru = spfItem.spf+'_'+spfItem.nameRu;  // соединяю spf и наименование в одну строку для удобного поиска
                      if (txt_ru.toLowerCase().indexOf(spfVal.toLowerCase()) !== -1) {    //---выполняется поиск по символам
                          spfArr.push({spf: newEl.spf, nameRu: newEl.nameRu, nameKk: newEl.nameKk, check: newEl.check});  //--добавляю копию в новый массив
                      }
                  } else {
                      spfArr.push({spf: newEl.spf, nameRu: newEl.nameRu, nameKk: newEl.nameKk, check: newEl.check});
                  }
              }
              spfArr.sort((a, b) => a.spf - b.spf);   //--сортировка по spf
          }
          this.spfList = [];
          spfArr.forEach((el) => {
              this.spfList.push(el);  //--обновляю массив с найденными символами
          });

          function checkSpfList(spfListInit, spfRemovedFromMainList) {
              if (spfRemovedFromMainList.length>0){
                  for (const spfEl of spfRemovedFromMainList){
                      const indx = spfListInit.findIndex(el => el.spf === spfEl.spf);
                      spfListInit.splice(indx, 1);
                  }
              }
              return spfListInit
          }
      },

      addSpf(){
        const arr = [];
        this.spfRemovedFromMainList = [];
        const spfList = this.spfList;
        this.spfList = [];
        if (spfList.length>0){
            for (const spfItem of spfList){
                if (spfItem.check === true){
                    arr.push(spfItem);
                }
            }
            this.addNewSpf(arr);    //---добавляем новые элементы в основную гриду
            for (const elItem of arr){
                const indx = spfList.findIndex(el => el.spf === elItem.spf);
                spfList.splice(indx, 1);
                this.spfRemovedFromMainList.push(elItem);
            }
            this.spfList = spfList;
        }
        this.modalSpfForm=false;
      },

      async addNewSpf(arr) {
          try {
              let regionKato = this.getRegionForKato(this.header.regDetails);
              regionKato = regionKato && regionKato!=='' ? regionKato : '000000';
              const response = await fetch('/api/budget-form-limit/factplanfin/' + this.curYear + '/' + this.currentRegion + '/' + this.obl + '/' + this.abp + '/' + this.prg + '/' + this.ppr + '/' + regionKato);
              const itemsArr = await response.json();
              // console.log('itemsArr: ' + JSON.stringify(itemsArr));
              const spfList = getPlanFinAndFact552Values(arr, itemsArr);
              if (spfList.length > 0) {
                // console.log('spfList: ' + JSON.stringify(spfList));
                  for (const item of spfList) {
                      let newObj = this.generateNewObj(item);

                    const shr = this.shrSpfList.find(el => parseInt(el) === parseInt(item.spf));
                    if (!shr) {
                      for (const indexItem of item.inflationIndex){
                        if (parseInt(new Date(indexItem.date).getFullYear()) === parseInt(this.curYear)) {
                          newObj.firstYearVal = indexItem.value;
                        } else if (parseInt(new Date(indexItem.date).getFullYear()) === parseInt(this.curYear+1)) {
                          newObj.secondYearVal = indexItem.value;
                        } else if (parseInt(new Date(indexItem.date).getFullYear()) === parseInt(this.curYear+2)) {
                          newObj.thirdYearVal = indexItem.value;
                        }
                      }
                      newObj.inflationRate = true;
                    } else {
                      this.shrMainList = await this.getShRList();
                      const checkNewShrData = this.checkNewShrData(this.shrMainList, this.budgetFactPlanArr);
                      if (checkNewShrData){
                        this.showInTop = true;
                      } else {
                        this.showInTop = false;
                      }
                    }
                    newObj = this.setShrSpfValInList(newObj);
                    newObj = this.setShrSpfValInList(newObj);
                    this.budgetFactPlanArr.push(newObj);
                  }
              }
          } catch (error) {
              this.makeToast('Ошибка', 'Ошибка запроса извлечение "Исполнения и Уточненного плана" из "budget_fact552", "budget_plan_fin" ', error.toString());
          }

          function getPlanFinAndFact552Values(fullArr, itemsArr){
              for (const el of fullArr){
                  const planFinSum = itemsArr && itemsArr.planFin && itemsArr.planFin.length>0 ? itemsArr.planFin.find(item => item.spf === el.spf): 0;
                  const fact552Sum = itemsArr && itemsArr.fact552 && itemsArr.fact552.length>0 ? itemsArr.fact552.find(item => item.spf === el.spf) : 0;
                  el.planFin = planFinSum && planFinSum.sum ? parseFloat(planFinSum.sum).toFixed(2) : parseFloat(0).toFixed(2);
                  el.fact552 = fact552Sum && fact552Sum.sum ? parseFloat(fact552Sum.sum).toFixed(2) : parseFloat(0).toFixed(2);
                  el.inflationIndex = itemsArr.inflationRateDto;
              }
              return fullArr;
          }
      },

      checkboxSelectAllSpf(val, data = this.spfList){
          if (this.checkButtonSpf !== this.checkCountSpf) { this.checkButtonSpf = 0; }

          data.forEach(item => {
              this.$set(item, 'check', val);
              this.onChecked(val);
          });
      },

      checkboxSelectAllRow(val, data = this.budgetFactPlanArr){
          if (this.checkRowButtonSpf !== this.checkRowCount) { this.checkRowButtonSpf = 0; }
          data.forEach(item => {
              this.$set(item, 'checkRow', val);
              this.onRowChecked(val);
          });
      },

      onChecked (e) {
          this.$nextTick(() => {
              if (e) {
                  this.checkButtonSpf++;
              } else {
                  this.checkButtonSpf--;
              }
              this.$emit('onChecked', this.checkButtonSpf);
              if (this.checkCountSpf === this.checkButtonSpf) {
                  this.selectAllSpf = true;
              }
          });

      },

      onRowChecked (e) {
          this.$nextTick(() => {
              if (e) {
                  this.checkRowButtonSpf++;
              } else {
                  this.checkRowButtonSpf--;
              }
              this.$emit('onChecked', this.checkRowButtonSpf);
              if (this.checkRowCount === this.checkRowButtonSpf) {
                  this.selectAllRows = true;
              }
          });

      },

      onInput (e) {
          if (!e) this.selectAllSpf = false;
      },

      onInputRow(e){
          if (!e) this.selectAllRows = false;
      },

      makeToast(title, tostbody) {
          this.$bvToast.toast(tostbody, {
              title: title,
              autoHideDelay: 5000,
              appendToast: true
          });
      }, // сообщение с ошибкой

      makeToastWarn(variant, title, tostbody) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }, // сообщение
    },

    computed: {
        // ----ИСПОЛНЕНИЕ ЗА (ПРОГНОЗНЫЙ ГОД-2) ГОД
        factSum() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.factSum !== '' ? row.factSum : 0.00);
            }
            sum = sum;
            return isNaN(sum) != true ? parseFloat(sum).toFixed(2) : 0.00;
        },
        // ----УТОЧНЕННЫЙ ПЛАН НА (ПРОГНОЗНЫЙ ГОД-1) Г. (БЕЗ РБ)
        planSum() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.planSum !== '' ? row.planSum : 0.00);
            }
            return isNaN(sum) != true ? parseFloat(sum).toFixed(2) : 0.00;
        },
        // ----РАЗОВЫЕ РАСХОДЫ
        oneTimeExpenses() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.oneTimeExpenses !== '' ? row.oneTimeExpenses : 0.00);
            }
            return isNaN(sum) != true ? Math.round(sum) : 0;
        },
        // ----УТОЧНЕННЫЙ ПЛАН НА 01.04.2021 Г. (БЕЗ УЧЕТА РАЗОВЫХ РАСХОДОВ)
        refinedPlan() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.refinedPlan !== '' ? row.refinedPlan : 0.00);
            }
            return isNaN(sum) != true ? Math.round(sum) : 0;
        },
        rbTransfert() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.rbTransfert !== '' ? row.rbTransfert : 0.00);
            }
            return isNaN(sum) != true ? Math.round(sum) : 0;
        },
        correctedPlan() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.correctedPlan !== '' ? row.correctedPlan : 0.00);
            }
            return isNaN(sum) != true ? Math.round(sum) : 0;
        },
        // ----ПРОГНОЗ НА 2022 ГОД
        forecastYearPlusOne() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.forecastYearPlusOne !== '' ? row.forecastYearPlusOne : 0.00);
            }
            return isNaN(sum) != true ? Math.round(sum) : 0;
        },
        // ----РАСХОДЫ, УЧТЕННЫЕ СВЕРХ ПАРАМЕТРОВ
        expensesInExcessOfParameters() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.expensesInExcessOfParameters !== '' ? row.expensesInExcessOfParameters : 0.00);
            }
            return isNaN(sum) != true ? Math.round(sum) : 0;
        },
        // ----% РОСТА
        percentOfGrowth() {
            let sumYearOne = 0;
            let sumRefinedPlan = 0;
            for (const row of this.budgetFactPlanArr) {
                sumYearOne += parseFloat(row.forecastYearPlusOne !== '' ? row.forecastYearPlusOne : 0.00);
                sumRefinedPlan += parseFloat(row.refinedPlan !== '' ? row.refinedPlan : 0.00);
            }
            return parseFloat(sumRefinedPlan) !== 0 ? parseFloat((sumYearOne / sumRefinedPlan) * 100).toFixed(2) : parseFloat('0').toFixed(2);
        },
        // -----ПРОГНОЗ 2023 ГОД
        twoYearPlusForecast() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.twoYearPlusForecast !== '' ? row.twoYearPlusForecast : 0.00);
            }
            return isNaN(sum) != true ? Math.round(sum) : 0;
        },
        // ----ПРОГНОЗ 2024 ГОД
        threeYearPlusForecast() {
            let sum = 0;
            for (const row of this.budgetFactPlanArr) {
                sum += parseFloat(row.threeYearPlusForecast !== '' ? row.threeYearPlusForecast : 0.00);
            }
            return isNaN(sum) != true ? Math.round(sum) : 0;
        },
        // ----% РОСТА 2023 ГОД
        twoYearPlusPercent() {
            let sumYearOne = 0;
            let sumYearTwo = 0;
            let percentGrowthTwo = 0;
            for (const row of this.budgetFactPlanArr) {
                sumYearOne += parseFloat(row.forecastYearPlusOne !== '' ? row.forecastYearPlusOne : 0.00);
                sumYearTwo += parseFloat(row.twoYearPlusForecast !== '' ? row.twoYearPlusForecast : 0.00);
            }
            percentGrowthTwo = parseFloat((sumYearTwo / sumYearOne) * 100).toFixed(2);
            return parseFloat(percentGrowthTwo).toFixed(2);
        },
        // ----% РОСТА 2024 ГОД
        threeYearPlusPercent() {
            let sumThreeYear = 0;
            let sumYearTwo = 0;
            let percentGrowthThree = 0;
            for (const row of this.budgetFactPlanArr) {
                sumThreeYear += parseFloat(row.threeYearPlusForecast !== '' ? row.threeYearPlusForecast : 0.00);
                sumYearTwo += parseFloat(row.twoYearPlusForecast !== '' ? row.twoYearPlusForecast : 0.00);
            }
            percentGrowthThree = parseFloat((sumThreeYear / sumYearTwo) * 100).toFixed(2);
            return parseFloat(percentGrowthThree).toFixed(2);
        },
        //---Итоговые значение в форме ком.услуг с spf 144
        totalValCountSpf144(){
          let sum = 0;
          for (const row of this.form144){
            sum += parseFloat(row.value!=='' ? row.value : 0);
          }
          return parseFloat(sum);
        },

        totalFirstCountSpf144(){
          let sum = 0;
          for (const row of this.form144) {
            sum += parseFloat(row.countFirstVal !== '' ? row.countFirstVal : 0.00);
          }
          return parseFloat(sum).toFixed(2);
        },
        totalSecondCountSpf144(){
          let sum = 0;
          for (const row of this.form144) {
            sum += parseFloat(row.countSecondVal !== '' ? row.countSecondVal : 0.00);
          }
          return parseFloat(sum).toFixed(2);
        },
        totalThirdCountSpf144(){
          let sum = 0;
          for (const row of this.form144) {
            sum += parseFloat(row.countThirdVal !== '' ? row.countThirdVal : 0.00);
          }
          return parseFloat(sum).toFixed(2);
        },
        //---Итоговые значение в форме ком.услуг с spf 151
        totalValCount(){
          let sum = 0;
          for (const row of this.commServicesArr){
            sum += parseFloat(row.value!=='' ? row.value : 0.00);
          }
          return parseFloat(sum).toFixed(2);
        },
        totalFirstCount(){
          let sum = 0;
          for (const row of this.commServicesArr) {
            sum += parseFloat(row.countFirstVal !== '' ? row.countFirstVal : 0.00);
          }
          return parseFloat(sum).toFixed(2);
        },
        totalSecondCount(){
          let sum = 0;
          for (const row of this.commServicesArr) {
            sum += parseFloat(row.countSecondVal !== '' ? row.countSecondVal : 0.00);
          }
          return parseFloat(sum).toFixed(2);
        },
        totalThirdCount(){
          let sum = 0;
          for (const row of this.commServicesArr) {
            sum += parseFloat(row.countThirdVal !== '' ? row.countThirdVal : 0.00);
          }
          return parseFloat(sum).toFixed(2);
        },
        isWarning(){
            let firstRow = 0;
            let secondRow = 0;
            if (this.form159.length > 0){
                for (const init of this.form159){
                    if (init.index === 1){
                        firstRow = parseFloat(init.countFirstVal) + parseFloat(init.countSecondVal) + parseFloat(init.countThirdVal);
                    } else {
                        secondRow = parseFloat(init.countFirstVal) + parseFloat(init.countSecondVal) + parseFloat(init.countThirdVal);
                    }
                }
            }
            return secondRow > firstRow;
        },   //--Если Оплата вывоза ТБО больше суммы по специфике 159, то выдавать предупреждение

        userId() {
            return this.$store.getters.user_uuid;
        }
    }
}
</script>

<style lang="scss" scoped>
.spf144And151{
    text-align: right;
}

.tempTextBubble{
    display: inline-block; /* Строчно-блочный элемент */
    position: relative; /* Относительное позиционирование */
}
.tempTextBubble:hover::after {
    content: attr(data-title); /* Выводим текст */
    position: absolute; /* Абсолютное позиционирование */
    left: -150px; top: 150%; /* Положение подсказки */
    z-index: 1; /* Отображаем подсказку поверх других элементов */
    background: rgba(255,255,230,0.9); /* Полупрозрачный цвет фона */
    font-family: Arial, sans-serif; /* Гарнитура шрифта */
    font-size: 24px; /* Размер текста подсказки */
    padding: 35px 50px; /* Поля */
    border: 1px solid #333; /* Параметры рамки */
}
.utilityServices{
    text-align: center;
}
.inputCommService{
    width: 80px;
}
.trTdCommService td:not(:first-child){
    width: 130px;
}
.trTdCommService td:first-child{
    width: 50px;
    text-align: center;
}
.warning-message{
    font-size: 14px;
    color: #df1919;
    margin: 12px;
}

.icon-summ{
    color: #01AC50;
    font-size: 20px;
}

.bg-tr1{
    background-color: #EEF1F8;
}
.td-bg-lightyellow{
    background-color: #FBF7EB;
}

.link-style{
    font-weight: 600;
    color: #178DEB;
    text-decoration: underline;
    cursor: pointer;
}

.bl-tr-sp1{
    td{
        padding: 5px 10px;
    }
}

input{
    min-width: 100px!important;
}

.error{
    .pop-up{
        width: 180px;
        text-align: left;
    }
    i{
        top: 0;
        left: 0;
    }
}

.bl-td-xx-with-input{
    display: flex;
    align-items: center;
    span{
        font-size: 12px;
        color: #6087A0;
        background-color: #fff;
        height: 27px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        border-radius: 5px 0 0 5px;
        border-top: 1px solid #BCD2E0;
        border-left: 1px solid #BCD2E0;
        border-bottom: 1px solid #BCD2E0;
    }
    input{
        border-radius: 0 5px 5px 0!important;
    }
}

.bl-td-xx{
    display: flex;
    align-items: center;
    span{
        font-size: 12px;
        color: #6087A0;
        position: relative;
    }
    .danger-text{
        color: #C0504C;
        margin: 2px 0 0 0;
        font-size: 14px;
        float: right;
    }
    .icon-danger{
        color: #C0504C;
        position: relative;
    }
    .bl-td-xx-left{
        position: relative;
        margin-right: 15px;
        text-align: right;
        .error{
            margin-top: 10px;
        }
        &:before{
            content: '';
            position: absolute;
            right: -7px;
            top: -10px;
            height: calc(100% + 20px);
            width: 1px;
            background-color: #D3DBE3;
        }
    }
}
</style>